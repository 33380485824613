import React, { useContext, useEffect } from "react";
import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import { getMe } from "./apiRoutes";
import { useApi } from "./useApi";
import AppShell from "./AppShell";
import { SkillsProvider } from "./context/SkillsContext";
import ScrollReset from "./utils/ScrollReset";
import ColorSchemeHandler from "./ColorSchemeHandler"; // New import

function App() {
  const { setUser, setIsLoggedIn, isLoggedIn } = useContext(UserContext);
  const api = useApi();

  useEffect(() => {
    const fetchUser = async () => {
      const user = JSON.parse(localStorage.getItem("user") as string);
      if (!user || !user.accessToken || !user.expiresAt) {
        setUser(null);
        setIsLoggedIn(false);
        return;
      }

      const isUserLoggedIn = Math.floor(Date.now() / 1000) <= user.expiresAt;
      setIsLoggedIn(isUserLoggedIn);
      if (!isUserLoggedIn) {
        setUser(null);
        return;
      }

      try {
        const user2 = await getMe(api);
        setUser(user2);
        setIsLoggedIn(true);
        console.log("User is login we put setIsLoggedIn true");
      } catch (error) {
        setUser(null);
        console.log("User is not login");
      }
    };

    fetchUser();
  }, [setUser, setIsLoggedIn, api]);

  const theme = createTheme({
    // fontFamily: "Inter, sans-serif",
    // colorScheme: user?.colorSchemeLight ? "light" : "dark",
    primaryColor: "theme",
    colors: {
      dark: [
        "#d5d7e0",
        "#acaebf",
        "#8c8fa3",
        "#666980",
        "#4d4f66",
        "#34354a",
        "#2b2c3d",
        "#0c0d21", // changed to purple from 1d1e30
        "#0c0d21",
        "#01010a",
      ],
      theme: [
        "#F3F0FF",
        "#E5DBFF",
        "#D0BFFF",
        "#B197FC",
        "#9775FA",
        "#845EF7",
        "#7950F2", // changed from violet
        "#7048E8", // changed from violet
        "#6741D9",
        "#5F3DC4",
      ],
    },
    headings: {
      sizes: {
        // h1: { fontWeight: 400 },
        // h2: { fontWeight: 400 },
      },
    },
    components: {
      Modal: {
        styles: () => ({
          title: { fontSize: 22 },
        }),
      },
      Progress: {
        styles: (theme: any) => ({
          root: {
            borderRadius: theme.radius.xl,
          },
          section: {
            borderRadius: theme.radius.xl,
          },
          label: {
            fontWeight: 500,
          },
        }),
      },
    },
  });

  if (isLoggedIn === undefined) return null;

  return (
    <MantineProvider theme={theme}>
      <ColorSchemeHandler />
      <Notifications position="top-right" />
      <ModalsProvider>
        <BrowserRouter>
          <ScrollReset />
          <SkillsProvider>
            <AppShell>
              <Router />
            </AppShell>
          </SkillsProvider>
        </BrowserRouter>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
