import { Badge, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { MiniGameCategory } from "../dto/user";
import { isMiniGameValidated } from "./DisplayResult";

interface FirstGameIndicatorProps {
  score?: number;
  category?: MiniGameCategory;
  children: React.ReactNode;
}

export const FirstGameIndicator = ({
  score,
  category,
  children,
}: FirstGameIndicatorProps) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    let animationFrame: number;
    const animate = () => {
      const time = Date.now() / 1000;
      setOffset(Math.sin(time * 2) * 10);
      animationFrame = requestAnimationFrame(animate);
    };
    animate();
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const isCompleted = isMiniGameValidated(score, category);

  if (isCompleted) {
    return <>{children}</>;
  }

  return (
    <div style={{ transform: `translateY(${offset}px)` }}>
      <Stack align="center" gap={5}>
        <Badge ta="center" size="lg" fw={500} radius="md">
          Commencer
        </Badge>
        {children}
      </Stack>
    </div>
  );
};
