import React, { useMemo } from "react";
import { areasOfLifeExtended } from "../dto/areasOfLife";
import {
  BackgroundImage,
  Badge,
  Box,
  Button,
  Group,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import SkillCard from "../Components/SkillCard";
import { useSkills } from "../context/SkillsContext";
import ChartEvolution from "../Components/ChartEvolution";
import { useEvolutionData } from "../hooks/useEvolutionData"; // Add this import
import AreasBarChart from "../Components/AreasBarChart";

const AreasOfLife = () => {
  const { area: areaValue } = useParams();
  const area = areasOfLifeExtended.find((a) => a.value === areaValue);
  const { skills, isLoading, error } = useSkills();
  const navigate = useNavigate();

  const filteredSkills = useMemo(() => {
    return skills
      ?.filter(
        (skill) =>
          (skill.area && skill.area.value === areaValue) ||
          (areaValue === "all" && skill.activated)
      )
      .sort((a, b) => {
        if (a.activated && !b.activated) return -1;
        if (!a.activated && b.activated) return 1;
        return 0;
      });
  }, [skills, areaValue]);

  const averageLevel = useMemo(() => {
    if (!filteredSkills || filteredSkills.length === 0) return 0;
    const totalLevel = filteredSkills.reduce(
      (sum, skill) => sum + (skill.userLevel || 0),
      0
    );
    return Math.round((totalLevel / filteredSkills.length) * 10) / 10;
  }, [filteredSkills]);

  const evolutionData = useEvolutionData(filteredSkills);

  if (isLoading) return <div>Loading skills...</div>;
  if (error) return <div>Error loading skills: {error.message}</div>;

  return (
    <>
      <BackgroundImage src={area?.backgroundImage ?? ""} mih={200} />
      <Box p={16}>
        <Group justify="space-between">
          <Title>
            {area?.emoji} {area?.title}
          </Title>
          <Group gap="md">
            {area?.id !== 0 && (
              <Button onClick={() => navigate(`/eval/area/${area?.id}`)}>
                Autoévaluation
              </Button>
            )}
            <Badge size="lg" variant="outline">
              Niveau {averageLevel}
            </Badge>
          </Group>
        </Group>
        <Text
          style={{
            color:
              "light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0))",
          }}
          mb="lg"
        >
          {area?.precision}
        </Text>

        <Box>
          <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
            {evolutionData.length ? (
              <>
                <ChartEvolution chartData={evolutionData} dataKey="total" />
                <AreasBarChart skills={filteredSkills} />
              </>
            ) : null}
          </SimpleGrid>
        </Box>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3, xl: 4 }} spacing="xl">
          {filteredSkills?.map((skill) => (
            <SkillCard key={skill.id} skill={skill} />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default AreasOfLife;
