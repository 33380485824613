import React from "react";
import { Modal, Text, Stack, Card, Group, Radio, Button } from "@mantine/core";
import { QuizQuestion } from "../dto/user";
import { IconCopy } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

interface QuizQuestionsModalProps {
  opened: boolean;
  onClose: () => void;
  questions: QuizQuestion[];
}

const QuizQuestionsModal: React.FC<QuizQuestionsModalProps> = ({
  opened,
  onClose,
  questions,
}) => {
  const parseAnswers = (answersJson: any) => {
    try {
      if (typeof answersJson === "string") {
        const parsed = JSON.parse(answersJson);
        const options = parsed.options || [];
        const correctAnswer = parsed.correctAnswer;
        return { options, correctAnswer };
      }
      return { options: [], correctAnswer: "" };
    } catch (e) {
      console.error("Error parsing answers:", e);
      return { options: [], correctAnswer: "" };
    }
  };

  const getLetterIndex = (letter: string) => {
    return letter.charCodeAt(0) - 65; // A=0, B=1, C=2, etc.
  };

  const handleCopyQuestions = () => {
    const formattedText = questions
      .map((question, index) => {
        const { options, correctAnswer } = parseAnswers(question.answers);
        const correctIndex = getLetterIndex(correctAnswer);

        const formattedOptions = options
          .map(
            (option: string, idx: number) =>
              `${String.fromCharCode(65 + idx)}) ${option}${
                idx === correctIndex ? " ✓" : ""
              }`
          )
          .join("\n");

        return `Question ${index + 1}: ${
          question.questionFr
        }\n\n${formattedOptions}\n\nExplication: ${question.explanationFr}\n\n`;
      })
      .join("\n");

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        notifications.show({
          title: "Copié !",
          message: "Les questions ont été copiées dans le presse-papiers",
          color: "green",
        });
      })
      .catch(() => {
        notifications.show({
          title: "Erreur",
          message: "Impossible de copier les questions",
          color: "red",
        });
      });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Questions du Quiz"
      size="100%"
    >
      <Group justify="flex-end" mb="md">
        <Button
          onClick={handleCopyQuestions}
          leftSection={<IconCopy size={16} />}
        >
          Copier toutes les questions
        </Button>
      </Group>

      <Stack>
        {questions.length === 0 ? (
          <Text>Aucune question trouvée pour ce quiz.</Text>
        ) : (
          <Stack>
            {questions.map((question, index) => {
              const { options, correctAnswer } = parseAnswers(question.answers);
              const correctIndex = getLetterIndex(correctAnswer);

              return (
                <Card key={question.id} withBorder shadow="sm" p="lg">
                  <Text size="lg" fw={500} mb="md">
                    Question {index + 1}: {question.questionFr}
                  </Text>

                  <Radio.Group value={correctAnswer}>
                    <Stack>
                      {options.map((option: string, optIndex: number) => (
                        <Group key={optIndex}>
                          <Radio
                            value={String.fromCharCode(65 + optIndex)}
                            label={option}
                            checked={optIndex === correctIndex}
                            readOnly
                          />
                          {optIndex === correctIndex && (
                            <Text size="sm" c="green">
                              ✓ Bonne réponse
                            </Text>
                          )}
                        </Group>
                      ))}
                    </Stack>
                  </Radio.Group>

                  <Text fw={500} mt="xl">
                    Explication:
                  </Text>
                  <Text>{question.explanationFr}</Text>
                </Card>
              );
            })}
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default QuizQuestionsModal;
