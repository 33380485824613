export const areasOfLife = [
  {
    id: 1,
    title: "Social",
    value: "social",
    emoji: "👍",
    backgroundImage:
      "https://images.pexels.com/photos/745045/pexels-photo-745045.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Renforce tes relations et développe un réseau social épanouissant.",
    precision:
      "Interactions, amitiés, communauté, activités sociales, networking et communication.",
  },
  {
    id: 2,
    title: "Professionnel",
    value: "professional",
    emoji: "🚚",
    backgroundImage:
      "https://images.pexels.com/photos/891059/pexels-photo-891059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Épanouis-toi dans ta carrière avec des stratégies de développement professionnel.",
    precision:
      "Carrière, compétences, accomplissements, progression, équilibre travail-vie personnelle et satisfaction professionnelle.",
  },
  {
    id: 3,
    title: "Finances",
    value: "finances",
    emoji: "💸",
    backgroundImage:
      "https://images.pexels.com/photos/164527/pexels-photo-164527.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description: "Maîtrise tes finances et atteins la liberté financière.",
    precision:
      "Gestion budgétaire, investissements, épargne, dettes, revenus et éducation financière.",
  },
  {
    id: 4,
    title: "Spiritualité",
    value: "spirituality",
    emoji: "✨",
    backgroundImage:
      "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Trouve ta paix intérieure et élève ta conscience spirituelle.",
    precision:
      "Croyances, pratiques spirituelles, méditation, sens de la vie et connexion.",
  },
  {
    id: 5,
    title: "Famille",
    value: "family",
    emoji: "🏠",
    backgroundImage:
      "https://images.pexels.com/photos/39691/family-pier-man-woman-39691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description: "Crée des liens familiaux solides et harmonieux.",
    precision:
      "Relations familiales, communication, temps de qualité, soutien mutuel et traditions.",
  },
  {
    id: 6,
    title: "Couple",
    value: "couple",
    emoji: "❤️",
    backgroundImage:
      "https://images.pexels.com/photos/1759823/pexels-photo-1759823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Construis et cultive des relations amoureuses épanouissantes.",
    precision:
      "Intimité, communication, confiance, projets communs, séduction, vie sexuelle et croissance ensemble.",
  },
  {
    id: 7,
    title: "Mental",
    value: "mental",
    emoji: "🧠",
    backgroundImage:
      "https://images.pexels.com/photos/799420/pexels-photo-799420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Adopte un mindset pour atteindre tes objectifs et surmonter les freins.",
    precision:
      "Pensées positives, gestion du stress, résilience, confiance en soi et développement personnel.",
  },
  {
    id: 8,
    title: "Santé",
    value: "health",
    emoji: "🍀",
    backgroundImage:
      "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description:
      "Adopte un mode de vie sain pour une meilleure qualité de vie.",
    precision:
      "Alimentation, exercice, sommeil, bien-être, prévention et gestion des maladies.",
  },
];

export const areasOfLifeExtended = [
  {
    id: 0,
    title: "Actifs",
    value: "all",
    emoji: "✅",
    backgroundImage:
      "https://images.pexels.com/photos/745045/pexels-photo-745045.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    description: "",
    precision: "Liste des compétences disponibles dans tous les domaines.",
  },
  ...areasOfLife,
];
