import React from "react";
import { Box, Title } from "@mantine/core";

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    style={{ justifyContent: "center" }}
    mt="lg"
    mb="xl"
    w="100%"
  >
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <Box
        style={{
          flexGrow: 1,
          flexShrink: 0,
          minWidth: "20px",
          height: "1px",
          backgroundColor: "var(--mantine-color-dark-2)",
        }}
      />
      <Title
        order={3}
        c="dimmed"
        style={{ textAlign: "center", padding: "0 0.5rem" }}
      >
        {children}
      </Title>
      <Box
        style={{
          flexGrow: 1,
          flexShrink: 0,
          minWidth: "20px",
          height: "1px",
          backgroundColor: "var(--mantine-color-dark-2)",
        }}
      />
    </Box>
  </Box>
);

export default SectionTitle;
