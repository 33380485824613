import { Journey } from "../dto/user";
import { JourneyType } from "../Pages/AdminJourneys";
import { areasOfLife } from "../dto/areasOfLife";

export const validatePseudo = (val: string) => {
  const pseudoRegex = /^[a-zA-Z0-9]{3,20}$/;
  if (!pseudoRegex.test(val)) {
    return "Le pseudo doit contenir entre 3 et 20 caractères, sans espaces, et uniquement des lettres et des chiffres";
  }
  return null;
};

export const getJourneyTitle = (journey: Journey | undefined): string => {
  if (!journey) return "";

  if (journey.type === JourneyType.AREA_OF_LIFE) {
    const area = areasOfLife.find((area) => area.id === journey.areaOfLifeId);
    return area ? area.title : "";
  }

  return journey.title || "";
};
