import {
  ActionIcon,
  Box,
  Center,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { MiniGameCategory, UserJourney } from "../dto/user";
import DisplayResult, {
  isMiniGameValidated,
} from "../Components/DisplayResult";
import JourneyProgress from "../Components/JourneyProgress";
import JourneySelect from "../Components/JourneySelect";
import { IconStarFilled, IconTrophy } from "@tabler/icons-react";
import SectionTitle from "../Components/SectionTitle";
import { FirstGameIndicator } from "../Components/FirstGameIndicator";

const getSectionTitle = (knowledgeId: number) => {
  if (knowledgeId === 37) return "Techniques de base";
  if (knowledgeId === 40) return "Attirer la sympathie";
  if (knowledgeId === 46) return "Persuasion efficace";
  if (knowledgeId === 52) return "Leadership positif";
  return "";
};

const sectionIds = [37, 40, 46, 52];

const Home = () => {
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const amplitude = 120;
  const wavePeriod = 8;

  const {
    data: userJourneys,
    isLoading,
    isFetching,
  } = useQuery<UserJourney[]>({
    queryKey: ["userJourneys"],
    queryFn: async () => {
      const response = await api.get("/journey/active-user-journeys");
      return response.data;
    },
  });

  const { data: mainJourney } = useQuery<UserJourney>({
    queryKey: ["mainJourney"],
    queryFn: () =>
      api.get("/journey/main-journey-details").then((res) => res.data),
  });

  const setMainJourneyMutation = useMutation({
    mutationFn: (journeyId: number) =>
      api.post("/journey/set-main-active", { journeyId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["mainJourney"] });
      queryClient.invalidateQueries({ queryKey: ["userJourneys"] });
    },
  });

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      (!userJourneys || userJourneys.length === 0)
    ) {
      navigate("/select-journey");
    }
  }, [userJourneys, isLoading, isFetching, navigate]);

  if (isLoading) {
    return <Box p={16}>Loading...</Box>;
  }

  if (!userJourneys || userJourneys.length === 0) {
    return null;
  }

  const currentJourneyId = mainJourney?.journey?.id?.toString();

  const isGamePlayable = (index: number) => {
    if (index === 0) return true;

    const previousGame = mainJourney?.journey?.knowledge
      ? mainJourney?.journey?.knowledge[index - 1]
      : undefined;
    const previousScore =
      previousGame?.knowledge?.miniGame?.results?.[0]?.score;
    const previousCategory = previousGame?.knowledge?.miniGame?.category;

    return isMiniGameValidated(previousScore, previousCategory);
  };

  const isGameReplayable = (
    gameScore: number | undefined,
    category: MiniGameCategory | undefined
  ) => {
    return isMiniGameValidated(gameScore, category);
  };

  return (
    <Box p={16}>
      <Center>
        <Group gap={10}>
          <JourneySelect
            currentJourneyId={currentJourneyId || null}
            userJourneys={userJourneys}
            onJourneyChange={(value) => setMainJourneyMutation.mutate(value)}
          />
          {mainJourney?.journey && (
            <JourneyProgress journey={mainJourney.journey} />
          )}
        </Group>
      </Center>

      {mainJourney?.journey?.knowledge && (
        <Box>
          <Stack mt="lg">
            {mainJourney.journey.knowledge.map((item, index) => {
              const bestScore = item.knowledge?.miniGame?.results?.[0]?.score;
              const category = item.knowledge?.miniGame?.category;
              const canPlay =
                isGamePlayable(index) || isGameReplayable(bestScore, category);
              const sectionTitle = getSectionTitle(item.knowledge?.id || 0);
              const showSection = sectionIds.includes(item.knowledge?.id || 0);

              const gameContent = (
                <Stack
                  style={{
                    marginLeft:
                      amplitude *
                      Math.sin(
                        (2 * Math.PI * (index - 2)) / wavePeriod + Math.PI / 2
                      ),
                  }}
                >
                  <Tooltip label={item.knowledge?.title || ""} withArrow>
                    <ActionIcon
                      size={60}
                      radius={60}
                      disabled={!canPlay}
                      onClick={() =>
                        navigate(`/mini-game/${item.knowledge?.miniGame?.id}`)
                      }
                    >
                      {item.knowledge?.isEvaluation ? (
                        <IconTrophy size={30} />
                      ) : (
                        <IconStarFilled size={30} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                  <div style={{ zIndex: 1, marginTop: -20, marginLeft: -3 }}>
                    <DisplayResult
                      category={category}
                      score={bestScore}
                      light
                    />
                  </div>
                </Stack>
              );

              return (
                <Box key={index}>
                  {showSection && <SectionTitle>{sectionTitle}</SectionTitle>}
                  <Center>
                    {index === 0 ? (
                      <FirstGameIndicator score={bestScore} category={category}>
                        {gameContent}
                      </FirstGameIndicator>
                    ) : (
                      gameContent
                    )}
                  </Center>
                </Box>
              );
            })}
          </Stack>
        </Box>
      )}
      <Center m="m" mt={30}>
        <Text size="22px">🏆</Text>
      </Center>
    </Box>
  );
};

export default Home;
