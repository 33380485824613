import { Text, Stack } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../useApi";
import React from "react";

interface MiniGameXpResultProps {
  miniGameId: number;
  newScore: number;
}

const calculateXp = (score: number) => Math.floor((score - 5) * 2);

const MiniGameXpResult = ({ miniGameId, newScore }: MiniGameXpResultProps) => {
  const api = useApi();
  const queryClient = useQueryClient();

  // Get previous best result for this mini-game
  const { data: previousResult } = useQuery({
    queryKey: ["miniGamePreviousBest", miniGameId],
    queryFn: () =>
      api.get(`/xp/mini-game/${miniGameId}/best-score`).then((res) => res.data),
  });

  // Calculate XP
  const newXp = calculateXp(newScore);
  const previousXp = previousResult ? calculateXp(previousResult.score) : 0;
  const xpGained = Math.max(0, newXp - previousXp);

  // Refresh global XP counter
  React.useEffect(() => {
    if (xpGained > 0) {
      queryClient.invalidateQueries({ queryKey: ["xp"] });
    }
  }, [xpGained, queryClient]);

  if (!xpGained) {
    return (
      <Text>
        Pas de nouveaux XP gagnés. Votre meilleur score reste plus élevé !
      </Text>
    );
  }

  return (
    <Stack gap="xs">
      {previousResult ? (
        <>
          <Text>
            Amélioration du score : {previousResult.score.toFixed(1)} →{" "}
            {newScore.toFixed(1)}
          </Text>
          <Text fw={500}>+{xpGained} XP gagnés !</Text>
        </>
      ) : (
        <Text fw={500}>+{xpGained} XP gagnés !</Text>
      )}
    </Stack>
  );
};

export default MiniGameXpResult;
