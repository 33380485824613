import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import {
  Box,
  Text,
  Progress,
  Title,
  LoadingOverlay,
  Alert,
  Button,
  useMantineTheme,
} from "@mantine/core";
import { IconAlertCircle, IconArrowLeft } from "@tabler/icons-react";
import SingleQuestionGame from "../Components/games-category/SingleQuestionGame";
import DOMPurify from "dompurify";
import ChatGame from "../Components/games-category/ChatGame";
import QuizGame from "../Components/games-category/QuizGame";
import { useMediaQuery } from "@mantine/hooks";
import { MiniGame as MiniGameType } from "../dto/user";
import QuitGameModal from "../Components/modals/QuitGameModal";

const MiniGame: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const api = useApi();
  const theme = useMantineTheme();
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
  const [gameState, setGameState] = useState<
    "not_started" | "started" | "finished" | "submitted"
  >(window.location.pathname === "/mini-game/1" ? "not_started" : "started");
  const [searchParams] = useSearchParams();
  const isMobile =
    useMediaQuery(`(max-width: ${theme.breakpoints.md})`) ||
    (gameState !== "started" && gameState !== "finished");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: gameData, isLoading: isGameDataLoading } =
    useQuery<MiniGameType>({
      queryKey: ["miniGame", id],
      queryFn: () => api.get(`/mini-games/${id}`).then((res) => res.data),
    });

  const hasTimeLimit = gameData?.timeLimit !== 0;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (gameState === "started" && timeLeft && timeLeft > 0 && hasTimeLimit) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          // @ts-ignore
          if (prevTime <= 1) {
            clearInterval(timer);
            setGameState("finished");
            return 0;
          }
          // @ts-ignore
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gameState, timeLeft, hasTimeLimit]);

  const handleReturnToSkill = () => {
    if (gameData && gameData.skillId) {
      navigate(`/skills/${gameData.skillId}`);
    }
  };

  if (isGameDataLoading) {
    return <LoadingOverlay visible={true} />;
  }

  if (!gameData) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur" color="red">
        Impossible de charger les données du mini-jeu. Veuillez réessayer plus
        tard.
      </Alert>
    );
  }

  const marginLeft =
    ["CHAT"].includes(gameData.category ?? "") && !isMobile ? 470 : undefined;

  return (
    <Box
      style={{
        position: "relative",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      <QuitGameModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      {(gameData.category !== "CHAT" || !isMobile) && (
        <Title order={2} mb="md" ml={marginLeft}>
          {gameData.title}
        </Title>
      )}
      {gameState === "started" && hasTimeLimit && timeLeft !== undefined && (
        <Box
          style={{
            width:
              isMobile || gameData.category !== "CHAT"
                ? "100%"
                : "calc(100% - 470px)",
          }}
          ml={marginLeft}
        >
          <Text mb="sm" fw={700}>
            Temps restant: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toString().padStart(2, "0")}
          </Text>
          <Progress
            value={(timeLeft / (gameData.timeLimit ?? 0)) * 100}
            mb="md"
            color={
              (timeLeft / (gameData.timeLimit ?? 0)) * 100 < 20
                ? "red"
                : (timeLeft / (gameData.timeLimit ?? 0)) * 100 < 40
                ? "yellow"
                : "blue"
            }
          />
        </Box>
      )}
      {gameState === "not_started" && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(gameData.description || ""),
          }}
        />
      )}
      {gameData.category === "SINGLE_QUESTION" && (
        <SingleQuestionGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
        />
      )}
      {gameData.category === "CHAT" && (
        <ChatGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft ?? 0}
          setTimeLeft={setTimeLeft}
          marginLeft={marginLeft}
        />
      )}
      {gameData.category === "QUIZ" && (
        <QuizGame
          gameData={gameData}
          gameState={gameState}
          setGameState={setGameState}
          timeLeft={timeLeft ?? 0}
          setTimeLeft={setTimeLeft}
          hasTimeLimit={hasTimeLimit}
        />
      )}

      {gameState === "submitted" && (
        <Box mt="xl">
          <Button
            leftSection={<IconArrowLeft size="1rem" />}
            onClick={() => {
              const origin = searchParams.get("origin");
              if (origin === "home" || !gameData.skillId) {
                navigate("/");
              } else {
                handleReturnToSkill();
              }
            }}
          >
            {searchParams.get("origin") === "home" || !gameData.skillId
              ? "Retourner à l'accueil"
              : "Retourner à la compétence"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MiniGame;
