import React, { useContext, useState, useEffect } from "react";
import { MultiSelect, Text } from "@mantine/core";
import { UserContext } from "../context/UserContext";
import { useApi } from "../useApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSkills } from "../context/SkillsContext";
import { areasOfLifeExtended } from "../dto/areasOfLife";

type SaveStatus = "saved" | "saving" | "unsaved" | "error" | null;

interface PrioritySkillsSectionProps {
  onSaveStatusChange: (status: SaveStatus) => void;
}

const useUpdatePrioritySkills = (
  onSaveStatusChange: (status: SaveStatus) => void
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  return useMutation({
    mutationFn: (prioritySkills: number[]) => {
      if (!user || !user.id) {
        throw new Error("User ID is not available");
      }
      onSaveStatusChange("saving");
      return api.put(`/user/${user.id}/priority-skills`, { prioritySkills });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["prioritySkills"] });
      onSaveStatusChange("saved");
    },
    onError: () => {
      onSaveStatusChange("error");
    },
  });
};

const usePrioritySkills = () => {
  const api = useApi();
  const { user } = useContext(UserContext);

  return useQuery({
    queryKey: ["prioritySkills"],
    queryFn: async () => {
      if (!user || !user.id) {
        throw new Error("User ID is not available");
      }
      const response = await api.get(`/user/${user.id}/priority-skills`);
      return response.data;
    },
    enabled: !!user?.id,
  });
};

const PrioritySkillsSection: React.FC<PrioritySkillsSectionProps> = ({
  onSaveStatusChange,
}) => {
  const { skills } = useSkills();
  const { data: prioritySkills } = usePrioritySkills();
  const [localPrioritySkills, setLocalPrioritySkills] = useState<string[]>([]);
  const updatePrioritySkillsMutation =
    useUpdatePrioritySkills(onSaveStatusChange);

  useEffect(() => {
    if (prioritySkills) {
      setLocalPrioritySkills(
        prioritySkills.map((skillId: number) => skillId.toString())
      );
    }
  }, [prioritySkills]);

  const handlePrioritySkillsChange = (newPrioritySkills: string[]) => {
    setLocalPrioritySkills(newPrioritySkills);
    onSaveStatusChange("unsaved");
    updatePrioritySkillsMutation.mutate(newPrioritySkills.map(Number));
  };

  const groupedSkills = areasOfLifeExtended.map((area) => ({
    group: `${area.emoji} ${area.title}`,
    items:
      skills
        ?.filter((skill) => skill.area?.value === area.value)
        .map((skill) => ({
          value: skill.id?.toString() ?? "",
          label: skill.name ?? "Unnamed Skill",
        }))
        .filter(
          (item): item is { value: string; label: string } =>
            item.value !== "" && item.label !== "Unnamed Skill"
        ) || [],
  }));

  return (
    <>
      <MultiSelect
        label="Compétences prioritaires"
        placeholder="Sélectionnez jusqu'à 10 compétences prioritaires"
        data={groupedSkills}
        value={localPrioritySkills}
        onChange={handlePrioritySkillsChange}
        maxValues={10}
        searchable
        mt={16}
      />
      <Text size="xs" mt={8} color="dimmed">
        Sélectionnez les compétences sur lesquelles vous souhaitez vous
        concentrer.
      </Text>
    </>
  );
};

export default PrioritySkillsSection;
