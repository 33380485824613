import { useState, useEffect } from "react";
import { UnstyledButton, Group, Avatar, Text, rem, Menu } from "@mantine/core";
import {
  IconChevronRight,
  // IconLogout,
  // IconMessageCircle2,
  // IconSettings,
} from "@tabler/icons-react";
import classes from "./UserButton.module.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
// import { useSkills } from "../context/SkillsContext";
import { useMediaQuery } from "@mantine/hooks";
import { resetApiInstance, useApi } from "../useApi";
import { useQuery } from "@tanstack/react-query";

export function UserButton({ close }: { close: () => void }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  // const { averageLevel } = useSkills();
  const [menuOpened, setMenuOpened] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width: 768px)");
  const api = useApi();

  const { data: xp } = useQuery({
    queryKey: ["xp"],
    queryFn: () => api.get("/xp").then((res) => res.data),
  });

  const closeMenu = () => setMenuOpened(false);

  useEffect(() => {
    if (isLargeScreen) {
      setMenuOpened(false);
    }
  }, [isLargeScreen]);

  return (
    <Menu
      opened={isLargeScreen ? undefined : menuOpened}
      onChange={setMenuOpened}
      trigger={isLargeScreen ? "hover" : "click"}
      openDelay={isLargeScreen ? 100 : 0}
      closeDelay={isLargeScreen ? 400 : 0}
      closeOnItemClick={!isLargeScreen}
    >
      <Menu.Target>
        <UnstyledButton
          style={{
            display: "block",
            width: "100%",
          }}
          className={classes.user}
          p="md"
        >
          <Group>
            <Avatar src={user?.profilePictureUrl} radius="xl" color="theme">
              {user?.pseudo ? user.pseudo.slice(0, 1).toUpperCase() : null}
            </Avatar>

            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {user?.pseudo}
              </Text>

              <Text c="dimmed" size="xs">
                XP : {xp ?? 0}
              </Text>
            </div>

            <IconChevronRight
              style={{ width: rem(14), height: rem(14) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          // leftSection={<IconSettings size={14} />}
          leftSection={"⚙️"}
          onClick={() => {
            if (!isLargeScreen) closeMenu();
            navigate("/settings");
            close();
          }}
        >
          Paramètres
        </Menu.Item>
        <Menu.Item
          leftSection={"💬"}
          // leftSection={<IconMessageCircle2 size={14} />}
          onClick={() => {
            if (!isLargeScreen) closeMenu();
            navigate("/feedback");
            close();
          }}
        >
          Feedback
        </Menu.Item>
        <Menu.Item
          leftSection={"🔓"}
          // leftSection={<IconLogout size={14} />}
          onClick={() => {
            if (!isLargeScreen) closeMenu();
            localStorage.removeItem("user");
            window.location.href = "/";
            resetApiInstance();
          }}
        >
          Se déconnecter
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
