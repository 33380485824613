import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Title, Button, TextInput, Tabs } from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { useApi } from "../useApi";
import { useForm } from "@mantine/form";
import RichTextEditorField from "../Components/RichTextEditorField";
import SortableKnowledgeItem from "../Components/SortableKnowledgeItem";
import KnowledgeGenerator from "../Components/KnowledgeGenerator";
import { getJourneyTitle } from "../utils/utils";

interface Knowledge {
  id: number;
  title: string;
  content: string;
  miniGame: {
    id: number;
    title: string;
  } | null;
  isEvaluation: boolean;
}

interface JourneyKnowledge {
  knowledge: Knowledge;
  order: number;
}

const AdminJourneyDetail = () => {
  const { journeyId } = useParams();
  const api = useApi();
  const queryClient = useQueryClient();
  const [localKnowledge, setLocalKnowledge] = useState<JourneyKnowledge[]>([]);

  const { data: journey } = useQuery({
    queryKey: ["journey", journeyId],
    queryFn: () => api.get(`/journey/${journeyId}`).then((res) => res.data),
  });

  const { data: knowledge } = useQuery<JourneyKnowledge[]>({
    queryKey: ["journeyKnowledge", journeyId],
    queryFn: () =>
      api.get(`/knowledge/journey/${journeyId}`).then((res) => res.data),
  });

  useEffect(() => {
    if (
      knowledge &&
      (localKnowledge.length === 0 ||
        JSON.stringify(localKnowledge) !== JSON.stringify(knowledge))
    ) {
      setLocalKnowledge(knowledge);
    }
  }, [knowledge, localKnowledge]);

  const form = useForm({
    initialValues: {
      title: "",
      content: "",
    },
  });

  const createKnowledgeMutation = useMutation<
    any,
    Error,
    {
      title: string;
      content: string;
    }
  >({
    mutationFn: async (values) => {
      const newKnowledge = await api.post("/knowledge", values);
      return api.post(`/knowledge/journey/${journeyId}`, {
        knowledgeId: newKnowledge.data.id,
        order: localKnowledge?.length || 0,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["journeyKnowledge", journeyId],
      });
      form.reset();
    },
  });

  const updateOrderMutation = useMutation({
    mutationFn: (orderedIds: { id: number; order: number }[]) =>
      api.put(`/knowledge/journey/${journeyId}/order`, { orderedIds }),
    onError: () => {
      if (knowledge) {
        setLocalKnowledge(knowledge);
      }
    },
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id && localKnowledge) {
      const oldIndex = localKnowledge.findIndex(
        (item) => item.knowledge.id === active.id
      );
      const newIndex = localKnowledge.findIndex(
        (item) => item.knowledge.id === over.id
      );

      const newOrder = arrayMove(localKnowledge, oldIndex, newIndex);
      setLocalKnowledge(newOrder);

      const orderedIds = newOrder.map((item, index) => ({
        id: item.knowledge.id,
        order: index,
      }));

      const timeoutId = setTimeout(() => {
        updateOrderMutation.mutate(orderedIds);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  };

  return (
    <Box p={16}>
      <Title mb={16}>Journey {getJourneyTitle(journey)}</Title>

      <Tabs defaultValue="list">
        <Tabs.List>
          <Tabs.Tab value="list">Liste des connaissances</Tabs.Tab>
          <Tabs.Tab value="generate">Générer des connaissances</Tabs.Tab>
          <Tabs.Tab value="add">Ajouter manuellement</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="list" pt="md">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={localKnowledge?.map((k) => k.knowledge.id) || []}
              strategy={verticalListSortingStrategy}
            >
              {localKnowledge?.map((item) => (
                <SortableKnowledgeItem
                  key={item.knowledge.id}
                  id={item.knowledge.id}
                  title={item.knowledge.title}
                  content={item.knowledge.content}
                  journeyId={journeyId}
                  miniGame={item.knowledge.miniGame}
                  isEvaluation={item.knowledge.isEvaluation}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Tabs.Panel>

        <Tabs.Panel value="generate" pt="md">
          <KnowledgeGenerator
            journeyId={journeyId!}
            onKnowledgeGenerated={() =>
              queryClient.invalidateQueries({
                queryKey: ["journeyKnowledge", journeyId],
              })
            }
          />
        </Tabs.Panel>

        <Tabs.Panel value="add" pt="md">
          <Box>
            <form
              onSubmit={form.onSubmit((values) =>
                createKnowledgeMutation.mutate(values)
              )}
            >
              <TextInput
                label="Titre"
                required
                {...form.getInputProps("title")}
                mb="md"
              />
              <RichTextEditorField
                content={form.values.content}
                onChange={(content) => form.setFieldValue("content", content)}
              />
              <Button
                type="submit"
                mt="md"
                loading={createKnowledgeMutation.isPending}
              >
                Ajouter
              </Button>
            </form>
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default AdminJourneyDetail;
