import React, { useState, useEffect, useCallback } from "react";
import { TextInput, Stack, Title } from "@mantine/core";
import { useApi } from "../../useApi";
import { AxiosError } from "axios";
import { useDebouncedValue } from "@mantine/hooks";
import { validatePseudo } from "../../utils/utils";

interface PseudoStepProps {
  onPseudoValidated: (pseudo: string) => void;
  onError: () => void;
  initialValue?: string; // Nouvelle prop
}

const PseudoStep: React.FC<PseudoStepProps> = ({
  onPseudoValidated,
  onError,
  initialValue = "",
}) => {
  const [pseudo, setPseudo] = useState(initialValue);
  const [error, setError] = useState("");
  const [, setIsChecking] = useState(false);
  const api = useApi();
  const [debouncedPseudo] = useDebouncedValue(pseudo, 300);

  useEffect(() => {
    // Supprimer cette validation initiale automatique
    // if (initialValue) {
    //   onPseudoValidated(initialValue);
    // }
  }, [initialValue, onPseudoValidated]);

  const checkPseudoAvailability = useCallback(
    async (pseudoToCheck: string) => {
      if (!pseudoToCheck) return;

      const validationError = validatePseudo(pseudoToCheck);
      if (validationError) {
        setError(validationError);
        onError();
        return;
      }

      setIsChecking(true);
      try {
        await api.post<{ available: boolean }>("/user/check-pseudo", {
          pseudo: pseudoToCheck,
        });
        setError("");
        onPseudoValidated(pseudoToCheck);
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 400) {
          setError("Ce pseudo est déjà utilisé");
          onError();
        }
      } finally {
        setIsChecking(false);
      }
    },
    [api, onError, onPseudoValidated]
  );

  useEffect(() => {
    if (debouncedPseudo) {
      checkPseudoAvailability(debouncedPseudo);
    }
  }, [debouncedPseudo, checkPseudoAvailability]);

  return (
    <Stack>
      <Title order={2}>Choisissez votre pseudo</Title>
      <TextInput
        value={pseudo}
        onChange={(e) => {
          setPseudo(e.target.value);
          if (!e.target.value) {
            setError("");
            onError();
          }
        }}
        error={error}
        required
        placeholder="Entrez votre pseudo"
        data-autofocus
      />
    </Stack>
  );
};

export default PseudoStep;
