import React from "react";
import { Badge, Text, Center, Group } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import { MiniGameCategory } from "../dto/user";

const FAILURE_MESSAGES = [
  {
    explicit: "Tu n'as pas réussi ce mini-jeu.",
    encouraging: "Ce n'est qu'un début ! On apprend beaucoup de nos erreurs 💪",
  },
  {
    explicit: "Score insuffisant pour valider.",
    encouraging: "Pas grave, chaque essai nous fait progresser 🌱",
  },
  {
    explicit: "Objectif non atteint cette fois-ci.",
    encouraging: "La prochaine fois sera la bonne ! 🎯",
  },
  {
    explicit: "Le mini-jeu n'est pas validé.",
    encouraging: "Rome ne s'est pas construite en un jour 🏗️",
  },
];

export const isMiniGameValidated = (
  score: number | undefined,
  category?: MiniGameCategory
): boolean => {
  if (!score || !category) return false;
  if (score === -1) return true;

  switch (category) {
    case "QUIZ":
      return score >= 8;
    default:
      return score >= 6.5;
  }
};

const DisplayResult = ({
  score,
  ml,
  light,
  failedExplicitly,
  category,
}: {
  score: number | undefined;
  ml?: number | string;
  light?: boolean;
  failedExplicitly?: boolean;
  category?: MiniGameCategory;
}) => {
  if (!category) {
    return (
      <Center>
        <Text c="red" size="sm">
          Erreur: Catégorie non définie
        </Text>
      </Center>
    );
  }

  let color = "red";
  let label = "Non validé";
  let filledStars = 0;
  const totalStars = 3;

  if (!score || (score < 6 && score !== -1)) {
    if (failedExplicitly) {
      const randomMessage =
        FAILURE_MESSAGES[Math.floor(Math.random() * FAILURE_MESSAGES.length)];
      return (
        <Group>
          <Badge color={color} size="lg" variant="filled" ml={ml}>
            {label}
          </Badge>
          <Text size="sm" c="dimmed">
            {randomMessage.encouraging}
          </Text>
        </Group>
      );
    }
    return null;
  }

  if (category === "QUIZ") {
    if (score >= 10) {
      filledStars = 3;
    } else if (score >= 9) {
      filledStars = 2;
    } else if (score >= 8) {
      filledStars = 1;
    } else {
      return (
        <Badge color="red" size="lg" variant="filled" ml={ml}>
          Non validé
        </Badge>
      );
    }
    color = "green";
    label = "Validé";
  } else {
    if (score >= 6) {
      color = "green";
      label = "Validé";
      if (score >= 8.5) {
        filledStars = 3;
      } else if (score >= 7.5) {
        filledStars = 2;
      } else if (score >= 6.5) {
        filledStars = 1;
      }
    }
  }

  if (score === -1) {
    color = "green";
    label = "Validé";
  }

  if (light) label = "";

  return (
    <Badge color={color} size="lg" variant="filled" ml={ml}>
      {label}{" "}
      {score !== -1 &&
        [...Array(totalStars)].map((_, i) => (
          <IconStar
            key={i}
            size={14}
            fill={i < filledStars ? "gold" : "none"}
            color="gold"
            style={{ marginBottom: -2 }}
          />
        ))}
    </Badge>
  );
};

export default DisplayResult;
