import React, { useState } from "react";
import {
  Box,
  Title,
  Table,
  Group,
  Button,
  Modal,
  TextInput,
  Select,
  Switch,
  Badge,
  ColorInput,
} from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Journey } from "../dto/user";
import { useApi } from "../useApi";
import { useForm } from "@mantine/form";
import RichTextEditorField from "../Components/RichTextEditorField";
import { areasOfLife } from "../dto/areasOfLife";
import { getJourneyTitle } from "../utils/utils";
import { useNavigate } from "react-router-dom";

export enum JourneyType {
  AREA_OF_LIFE = "AREA_OF_LIFE",
  TRACK = "TRACK",
}

const getJourneyType = (type: JourneyType): string => {
  switch (type) {
    case JourneyType.AREA_OF_LIFE:
      return "Domaine de vie";
    case JourneyType.TRACK:
      return "Track";
    default:
      return "Type inconnu";
  }
};

const AdminJourneys = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [modalOpened, setModalOpened] = useState(false);
  const [editingJourney, setEditingJourney] = useState<Journey | null>(null);
  const navigate = useNavigate();

  const { data: journeys, isLoading } = useQuery<Journey[], Error>({
    queryKey: ["journeys"],
    queryFn: () => api.get("/journey/journeys").then((res) => res.data),
  });

  const createEditJourneyMutation = useMutation({
    mutationFn: (journey: Omit<Journey, "id"> | Journey) =>
      "id" in journey
        ? api.put(`/journey/${journey.id}`, journey)
        : api.post("/journey", journey),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["journeys"] });
      setModalOpened(false);
      form.reset();
      setEditingJourney(null);
    },
  });

  const form = useForm({
    initialValues: {
      id: 0,
      title: "",
      description: "",
      type: JourneyType.TRACK,
      areaOfLifeId: null as string | null,
      order: 0,
      activated: false,
      backgroundColor: "#FFFFFF",
    },
    validate: {
      title: (value, values) =>
        value.length > 0 || values.type !== JourneyType.TRACK
          ? null
          : "Le titre est requis",
      type: (value) => (value ? null : "Le type est requis"),
      areaOfLifeId: (value, values) =>
        values.type === JourneyType.AREA_OF_LIFE && !value
          ? "Le domaine de vie est requis pour un journey de type 'Domaine de vie'"
          : null,
    },
  });

  const handleOpenModal = (journey?: Journey) => {
    if (journey) {
      setEditingJourney(journey);
      form.setValues({
        id: journey.id,
        title: journey.title || "",
        description: journey.description || "",
        type: journey.type || JourneyType.TRACK,
        areaOfLifeId: journey.areaOfLifeId?.toString(),
        order: journey.order || 0,
        activated: journey.activated ?? true,
        backgroundColor: journey.backgroundColor || "#FFFFFF",
      });
    } else {
      setEditingJourney(null);
      form.reset();
    }
    setModalOpened(true);
  };

  const handleSubmit = (values: typeof form.values) => {
    const journeyData: Partial<Journey> = {
      title: values.title,
      description: values.description,
      type: values.type,
      areaOfLifeId:
        values.type === JourneyType.AREA_OF_LIFE
          ? values.areaOfLifeId
            ? parseInt(values.areaOfLifeId.toString(), 10)
            : null
          : null,
      order: +values.order,
      activated: values.activated,
      backgroundColor: values.backgroundColor,
    };
    if (editingJourney) {
      journeyData.id = editingJourney.id;
    }
    createEditJourneyMutation.mutate(journeyData as Journey);
  };

  if (isLoading) return <div>Chargement...</div>;

  return (
    <Box p={16}>
      <Group mb={16}>
        <Title>Gestion des Journeys</Title>
        <Button onClick={() => handleOpenModal()}>
          Créer un nouveau journey
        </Button>
      </Group>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Titre</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Ordre</Table.Th>
            <Table.Th>Couleur</Table.Th>
            <Table.Th>Statut</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {journeys?.map((journey: Journey) => (
            <Table.Tr
              key={journey.id}
              onClick={() => handleOpenModal(journey)}
              style={{ cursor: "pointer" }}
            >
              <Table.Td>{journey.id}</Table.Td>
              <Table.Td>{getJourneyTitle(journey)}</Table.Td>
              <Table.Td>{getJourneyType(journey.type!)}</Table.Td>
              <Table.Td>{journey.order}</Table.Td>
              <Table.Td>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: journey.backgroundColor || "#FFFFFF",
                    border: "1px solid #ccc",
                    borderRadius: 4,
                  }}
                />
              </Table.Td>
              <Table.Td>
                {journey.activated ? (
                  <Badge size="xs" color="green">
                    Actif
                  </Badge>
                ) : undefined}
              </Table.Td>
              <Table.Td>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/admin-journey/${journey.id}`);
                  }}
                  size="xs"
                >
                  Gérer les connaissances
                </Button>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingJourney(null);
          form.reset();
        }}
        size="lg"
        title={
          editingJourney ? "Modifier le journey" : "Créer un nouveau journey"
        }
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Select
            label="Type"
            placeholder="Sélectionnez le type"
            data={[
              { value: JourneyType.AREA_OF_LIFE, label: "Domaine de vie" },
              { value: JourneyType.TRACK, label: "Track" },
            ]}
            {...form.getInputProps("type")}
            mt="md"
            required
          />

          {form.values.type === JourneyType.AREA_OF_LIFE && (
            <Select
              label="Domaine de vie"
              placeholder="Sélectionnez un domaine de vie"
              data={areasOfLife.map((area) => ({
                value: area.id.toString(),
                label: `${area.emoji} ${area.title}`,
              }))}
              {...form.getInputProps("areaOfLifeId")}
              mt="md"
              required
            />
          )}

          {form.values.type === JourneyType.TRACK && (
            <TextInput
              label="Titre"
              placeholder="Entrez le titre du journey"
              {...form.getInputProps("title")}
            />
          )}

          <RichTextEditorField
            content={form.values.description}
            onChange={(description) =>
              form.setFieldValue("description", description)
            }
          />

          <Group grow align="flex-start" mt="md">
            <TextInput
              label="Ordre"
              type="number"
              placeholder="Ordre d'affichage"
              {...form.getInputProps("order")}
            />

            <ColorInput
              label="Couleur de fond"
              placeholder="Choisir une couleur"
              {...form.getInputProps("backgroundColor")}
              format="hex"
              swatches={[
                "#92dbbb", // vert pastel clair
                "#E6E6FA", // lavande pastel
                "#FFB6C1", // rose pastel
                "#cbd8ff", // bleu poudré
                "#FFE4B5", // pêche pastel
                "#DDA0DD", // prune pastel
                "#F0FFF0", // vert menthe très clair
                "#FFF0F5", // rose lavande très clair
                "#E0FFFF", // cyan très clair
                "#FEBFBB", // rose saumon très clair
                "#F5DEB3", // beige pastel
                "#D8BFD8", // violet pastel
              ]}
            />
          </Group>

          <Switch
            label="Journey activé"
            {...form.getInputProps("activated", { type: "checkbox" })}
            mt="xl"
            onLabel="ON"
            offLabel="OFF"
          />

          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={createEditJourneyMutation.isPending}>
              {editingJourney ? "Mettre à jour" : "Créer"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default AdminJourneys;
