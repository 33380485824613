import {
  Box,
  SegmentedControl,
  Title,
  Badge,
  Group,
  Flex,
  Button,
  ActionIcon,
  Tooltip,
  Modal,
  Card,
  Loader,
  Text,
  ScrollArea,
  Stack,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useState, useEffect, useCallback } from "react";
import { useSkills } from "../context/SkillsContext";
import BeautifulLifeTree from "../Components/BeautifulTree";
import { areasOfLife } from "../dto/areasOfLife";
import { Skill, Task } from "../dto/user";
import {
  IconSortAscending,
  IconSortDescending,
  IconX,
  IconArrowsExchange,
  IconBulb,
  IconTrophy,
  IconCopy,
} from "@tabler/icons-react";
import { useApi } from "../useApi";
import { useMutation } from "@tanstack/react-query";
import DomainView from "../Components/DomainView";
import ListView from "../Components/ListView";

// Types de vues disponibles
type ViewType = "domains" | "tree" | "list";
type SortField = "level" | "importance" | "gap" | null;
type SortDirection = "asc" | "desc" | null;

const Brain = () => {
  const { skills } = useSkills();
  const [activeView, setActiveView] = useState<ViewType>("domains");
  const [sortField, setSortField] = useState<SortField>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(null);
  const api = useApi();

  // Nouveaux états pour la gestion des quêtes quotidiennes
  const [modalOpen, setModalOpen] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);

  // État simplifié pour le nombre global de quêtes terminées
  const [completedQuestsCount, setCompletedQuestsCount] = useState(0);

  // Fonction pour récupérer uniquement le nombre de quêtes terminées
  const fetchCompletedQuestsCount = useCallback(async () => {
    try {
      const response = await api.get("/daily-proposals/stats/global");
      if (response.data && response.data.completed !== undefined) {
        setCompletedQuestsCount(response.data.completed);
      }
    } catch (error) {
      console.error(
        "Erreur lors du chargement du nombre de quêtes terminées:",
        error
      );
    }
  }, [api]);

  // Charger le nombre de quêtes terminées au démarrage et quand les tâches changent
  useEffect(() => {
    fetchCompletedQuestsCount();
  }, [fetchCompletedQuestsCount, tasks]);

  // Mutation pour obtenir ou générer les propositions quotidiennes
  const dailyProposalsMutation = useMutation({
    mutationFn: () => {
      // Obtenir la date locale de l'utilisateur
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString(); // Mois 1-indexed pour l'API
      const day = now.getDate().toString();

      console.log("Date envoyée:", { year, month, day });
      // Envoyer directement la date locale au backend sans conversion
      return api.get(`/daily-proposals?year=${year}&month=${month}&day=${day}`);
    },
    onSuccess: (response) => {
      if (response.data && response.data.tasks) {
        setTasks(response.data.tasks);

        // Vérifier s'il y a déjà une tâche sélectionnée
        const selectedTask = response.data.tasks.find(
          (task: Task) => task.isSelected
        );
        if (selectedTask) {
          setSelectedTaskId(selectedTask.id);
        } else {
          setSelectedTaskId(null);
        }
      }
    },
    onError: (error) => {
      console.error(
        "Erreur lors du chargement des propositions quotidiennes:",
        error
      );
    },
  });

  // Fonction pour marquer une tâche comme sélectionnée
  const selectTaskMutation = useMutation({
    mutationFn: (taskId: string) =>
      api.post(`/daily-proposals/tasks/${taskId}/select`),
    onSuccess: (response, taskId) => {
      console.log("Tâche sélectionnée:", taskId);
      const fullContent = response.data?.fullContent || "";

      setTasks(
        tasks.map((task) =>
          task.id === taskId
            ? {
                ...task,
                isSelected: true,
                fullContent: fullContent,
              }
            : task
        )
      );

      // Mettre à jour l'état pour n'afficher que la tâche sélectionnée
      setSelectedTaskId(taskId);
    },
  });

  // Fonction pour marquer une tâche comme terminée
  const completeTaskMutation = useMutation({
    mutationFn: (taskId: string) =>
      api.post(`/daily-proposals/tasks/${taskId}/complete`),
    onSuccess: (_response, taskId) => {
      setTasks(
        tasks.map((task) =>
          task.id === taskId
            ? {
                ...task,
                completed: true,
                completedAt: new Date().toISOString(),
              }
            : task
        )
      );

      // Actualiser le nombre de quêtes terminées
      fetchCompletedQuestsCount();
    },
  });

  if (!skills) return null;

  // Fonction pour obtenir le niveau et l'importance d'une compétence
  const getSkillData = (skill: Skill) => {
    const level = skill.userLevel || 0;
    const importance = skill.userImportance || 0;
    const gap = importance - level; // Écart entre importance et niveau

    return {
      level,
      importance,
      gap,
    };
  };

  // Fonction pour trier les compétences selon les options choisies
  const sortSkills = (skillsArray: Skill[]) => {
    if (!sortField || !sortDirection) return skillsArray;

    return [...skillsArray].sort((a, b) => {
      let valueA, valueB;

      if (sortField === "gap") {
        // Calcul de l'écart pour chaque compétence
        const gapA = (a.userImportance || 0) - (a.userLevel || 0);
        const gapB = (b.userImportance || 0) - (b.userLevel || 0);
        valueA = gapA;
        valueB = gapB;
      } else {
        valueA =
          sortField === "level" ? a.userLevel || 0 : a.userImportance || 0;
        valueB =
          sortField === "level" ? b.userLevel || 0 : b.userImportance || 0;
      }

      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    });
  };

  // Gestion du changement de tri
  const handleSortFieldChange = (field: SortField) => {
    if (sortField === field) {
      // Si on clique sur le même champ, on change la direction ou on réinitialise
      if (!sortDirection) setSortDirection("desc");
      else if (sortDirection === "desc") setSortDirection("asc");
      else {
        setSortField(null);
        setSortDirection(null);
      }
    } else {
      // Nouveau champ sélectionné
      setSortField(field);
      setSortDirection("desc"); // Par défaut décroissant
    }
  };

  // Organiser les compétences par domaine de vie (avec tri si applicable)
  const skillsByArea = areasOfLife.reduce((acc, area) => {
    const filteredSkills = skills.filter(
      (skill) => skill.area?.value === area.value
    );
    acc[area.value] =
      sortField && sortDirection ? sortSkills(filteredSkills) : filteredSkills;
    return acc;
  }, {} as Record<string, Skill[]>);

  // Liste triée complète pour la vue "list"
  const sortedSkills = sortField && sortDirection ? sortSkills(skills) : skills;

  // Obtenir le style du bouton de tri
  const getSortButtonStyle = (field: SortField) => {
    if (sortField !== field) return { variant: "light" as const };
    return { variant: "filled" as const, color: "blue" };
  };

  // Obtenir l'icône de direction
  const getSortDirectionIcon = () => {
    if (!sortDirection) return <IconX size={16} />;
    return sortDirection === "asc" ? (
      <IconSortAscending size={16} />
    ) : (
      <IconSortDescending size={16} />
    );
  };

  // Fonction pour récupérer/générer les tâches quotidiennes
  const fetchDailyTasks = () => {
    setModalOpen(true);
    dailyProposalsMutation.mutate();
  };

  // Fonction pour sélectionner une tâche
  const selectTask = (taskId: string) => {
    selectTaskMutation.mutate(taskId);
  };

  // Fonction pour compléter une tâche
  const completeTask = (taskId: string) => {
    completeTaskMutation.mutate(taskId);
  };

  // Fonction pour exporter les compétences dans le presse-papier
  const exportSkillsToClipboard = () => {
    if (!skills) return;

    const skillsText = skills
      .map((skill) => {
        const level = skill.userLevel || 0;
        const importance = skill.userImportance || 0;
        return `${skill.name} | Niveau: ${level} | Importance: ${importance}`;
      })
      .join("\n");

    navigator.clipboard
      .writeText(skillsText)
      .then(() => {
        notifications.show({
          title: "Export réussi",
          message: "Les compétences ont été copiées dans le presse-papier",
          color: "green",
        });
      })
      .catch(() => {
        notifications.show({
          title: "Erreur",
          message: "Impossible de copier dans le presse-papier",
          color: "red",
        });
      });
  };

  // Rendu des différentes vues
  const renderView = () => {
    switch (activeView) {
      case "domains":
        return (
          <DomainView skillsByArea={skillsByArea} getSkillData={getSkillData} />
        );
      case "tree":
        return <BeautifulLifeTree />;
      case "list":
        return (
          <ListView sortedSkills={sortedSkills} getSkillData={getSkillData} />
        );
      default:
        return null;
    }
  };

  // Rendu de la modale pour afficher les tâches générées
  const renderTasksModal = () => {
    // Filtrer les tâches selon qu'une tâche est sélectionnée ou non
    const visibleTasks = selectedTaskId
      ? tasks.filter((task) => task.id === selectedTaskId)
      : tasks;

    return (
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Quêtes quotidiennes"
        fullScreen
      >
        {dailyProposalsMutation.isPending ? (
          <Flex
            align="center"
            justify="center"
            direction="column"
            gap="md"
            p="xl"
          >
            <Loader size="lg" />
            <Text>Chargement de vos quêtes quotidiennes...</Text>
          </Flex>
        ) : (
          <Box>
            <Stack mb="xl">
              <Group justify="apart" mb="xs">
                <Text size="lg" fw={700}>
                  {selectedTaskId
                    ? "Quête sélectionnée"
                    : "Vos 3 quêtes du jour"}
                </Text>
              </Group>
            </Stack>

            {visibleTasks.map((task) => (
              <Card
                key={task.id}
                shadow="sm"
                p="md"
                radius="md"
                withBorder
                mb="md"
              >
                <Group justify="space-between" mb="xs">
                  <Text fw={700}>{task.title}</Text>
                  <Badge
                    color={
                      task.category === "KNOWLEDGE"
                        ? "blue"
                        : task.category === "ONLINE_ACTION"
                        ? "green"
                        : "orange"
                    }
                  >
                    {task.category === "KNOWLEDGE"
                      ? "Connaissance"
                      : task.category === "ONLINE_ACTION"
                      ? "Action en ligne"
                      : "Action concrète"}
                  </Badge>
                </Group>
                <Text>Compétence: {task.skill?.name}</Text>

                {/* Description courte si pas sélectionnée */}
                {!task.isSelected && (
                  <Text mt="sm">{task.shortDescription}</Text>
                )}

                {/* Contenu complet si sélectionnée */}
                {task.isSelected && (
                  <ScrollArea
                    h={200}
                    mt="sm"
                    mb="md"
                    type="auto"
                    scrollbarSize={6}
                  >
                    {task.fullContent ? (
                      <Text style={{ whiteSpace: "pre-line" }}>
                        {task.fullContent}
                      </Text>
                    ) : (
                      <Text fs="italic" c="dimmed">
                        Chargement du contenu...
                      </Text>
                    )}
                  </ScrollArea>
                )}

                <Group justify="right" mt="md">
                  {!task.isSelected && !task.completed && (
                    <Button
                      size="sm"
                      color="blue"
                      onClick={() => selectTask(task.id)}
                      loading={selectTaskMutation.isPending}
                    >
                      Sélectionner
                    </Button>
                  )}
                  {task.isSelected && !task.completed && (
                    <Button
                      size="sm"
                      color="green"
                      onClick={() => completeTask(task.id)}
                      loading={completeTaskMutation.isPending}
                    >
                      Marquer comme terminée
                    </Button>
                  )}
                  {task.completed && <Badge color="green">Terminée</Badge>}
                </Group>
              </Card>
            ))}
          </Box>
        )}
      </Modal>
    );
  };

  return (
    <Box>
      <Group justify="space-between" mb="md">
        <SegmentedControl
          value={activeView}
          onChange={(value) => setActiveView(value as ViewType)}
          data={[
            { value: "domains", label: "Domaines" },
            { value: "tree", label: "Arbre de vie" },
            { value: "list", label: "Liste" },
          ]}
          w="100%"
        />

        <Group mb="xs" p="md" justify="space-between">
          <Group>
            <Title order={4}>Tri</Title>
            <Group gap={4}>
              <Button
                {...getSortButtonStyle("level")}
                onClick={() => handleSortFieldChange("level")}
                size="xs"
              >
                Niveau
              </Button>
              <Button
                {...getSortButtonStyle("importance")}
                onClick={() => handleSortFieldChange("importance")}
                size="xs"
              >
                Importance
              </Button>
              <Tooltip label="Trier par écart entre importance et niveau">
                <Button
                  {...getSortButtonStyle("gap")}
                  onClick={() => handleSortFieldChange("gap")}
                  size="xs"
                  leftSection={<IconArrowsExchange size={14} />}
                >
                  Écart
                </Button>
              </Tooltip>
            </Group>

            {sortField && (
              <ActionIcon
                variant={sortDirection ? "filled" : "subtle"}
                color="blue"
                onClick={() => {
                  if (sortDirection === "desc") setSortDirection("asc");
                  else if (sortDirection === "asc") setSortDirection(null);
                  else setSortDirection("desc");
                }}
              >
                {getSortDirectionIcon()}
              </ActionIcon>
            )}

            {(sortField || sortDirection) && (
              <ActionIcon
                variant="subtle"
                onClick={() => {
                  setSortField(null);
                  setSortDirection(null);
                }}
              >
                <IconX size={16} />
              </ActionIcon>
            )}
          </Group>
        </Group>

        {/* Groupe avec badge de quêtes terminées et boutons */}
        <Group m={16}>
          <Badge
            size="xl"
            variant="filled"
            color="green"
            leftSection={<IconTrophy size={16} />}
          >
            {completedQuestsCount} quêtes complétées
          </Badge>

          <Tooltip label="Exporter les compétences">
            <Button
              leftSection={<IconCopy size={18} />}
              onClick={exportSkillsToClipboard}
              variant="light"
              color="blue"
            >
              Exporter
            </Button>
          </Tooltip>

          <Button
            leftSection={<IconBulb size={18} />}
            onClick={fetchDailyTasks}
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan" }}
          >
            Quêtes quotidiennes
          </Button>
        </Group>
      </Group>

      {renderView()}
      {renderTasksModal()}
    </Box>
  );
};

export default Brain;
