import React from "react";
import { Box, Text, Progress, Group } from "@mantine/core";
import { Journey } from "../dto/user";
import { isMiniGameValidated } from "./DisplayResult";

const JourneyProgress = ({ journey }: { journey: Journey }) => {
  if (!journey?.knowledge || journey.knowledge.length === 0) {
    return null;
  }

  const totalGames = journey.knowledge.length;
  const completedGames = journey.knowledge.reduce((acc, item) => {
    const score = item.knowledge?.miniGame?.results?.[0]?.score;
    const category = item.knowledge?.miniGame?.category;
    return acc + (isMiniGameValidated(score, category) ? 1 : 0);
  }, 0);

  const progressPercentage = Math.round((completedGames / totalGames) * 100);

  return (
    <Box mt={-8}>
      <Group justify="space-between" mb={5}>
        <Text size="sm" fw={500}>
          Progression
        </Text>
        <Text size="sm" fw={500}>
          {progressPercentage}%
        </Text>
      </Group>
      <Progress value={progressPercentage} color="blue" radius="xl" />
    </Box>
  );
};

export default JourneyProgress;
