import { Box, Text, Paper, Title, Badge, Group } from "@mantine/core";
import { Skill } from "../dto/user";
import { areasOfLife } from "../dto/areasOfLife";

interface ListViewProps {
  sortedSkills: Skill[];
  getSkillData: (skill: Skill) => {
    level: number;
    importance: number;
    gap: number;
  };
}

const ListView = ({ sortedSkills, getSkillData }: ListViewProps) => {
  return (
    <Box p="md">
      <Title order={3} mb="md">
        Liste des compétences
      </Title>
      {sortedSkills.map((skill) => {
        const { level, importance, gap } = getSkillData(skill);
        const area = areasOfLife.find((a) => a.value === skill.area?.value);
        return (
          <Paper key={skill.id} p="md" mb="md" withBorder>
            <Group justify="space-between">
              <Group>
                <Text>{area?.emoji}</Text>
                <Text fw={500}>{skill.name}</Text>
              </Group>
              <Group>
                <Badge color="blue">Niveau: {level}</Badge>
                <Badge color="grape">Importance: {importance}</Badge>
                <Badge
                  color={gap > 0 ? "red" : gap < 0 ? "green" : "gray"}
                  variant="light"
                >
                  Écart: {gap > 0 ? "+" : ""}
                  {gap}
                </Badge>
              </Group>
            </Group>
          </Paper>
        );
      })}
    </Box>
  );
};

export default ListView;
