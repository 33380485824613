import React, { useEffect } from "react";
import { Stack, Text, Paper } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import DisplayResult from "../DisplayResult";
import { confettiEffects } from "../../utils/confettiEffects";
import MiniGameXpResult from "./MiniGameXpResult";
import { MiniGame } from "../../dto/user";

interface GameResultProps {
  title?: string;
  score: number | null;
  totalQuestions?: number;
  feedback?: string;
  userAnswer?: string;
  miniGame: MiniGame;
}

const GameResult: React.FC<GameResultProps> = ({
  title = "Jeu terminé !",
  score,
  totalQuestions,
  feedback,
  userAnswer,
  miniGame,
}) => {
  useEffect(() => {
    // Déclencher les confettis si le score est >= 8.5
    if (score !== null && score >= 8.5) {
      const randomEffect =
        confettiEffects[Math.floor(Math.random() * confettiEffects.length)];
      randomEffect();
    }
  }, [score]);

  return (
    <Stack>
      {/* Title */}
      <Text fw={700} size="lg" mb="xs">
        {title}
      </Text>

      {/* User Answer (for SingleQuestion game) */}
      {userAnswer && (
        <Paper p="md" withBorder>
          <Text fw={700} mb="xs">
            Votre réponse :
          </Text>
          <Text>{userAnswer}</Text>
        </Paper>
      )}

      {/* Score Display */}
      {score !== null && (
        <>
          {totalQuestions ? (
            <Text>
              Votre score : {score} / {totalQuestions}
            </Text>
          ) : null}
          <DisplayResult
            score={score}
            failedExplicitly
            category={miniGame?.category}
          />
          <MiniGameXpResult newScore={score} miniGameId={miniGame?.id ?? 0} />
        </>
      )}

      {/* Feedback */}
      {feedback && (
        <Paper p="md" withBorder>
          <Text fw={700} mb="xs">
            Feedback :
          </Text>
          <ReactMarkdown>{feedback}</ReactMarkdown>
        </Paper>
      )}
    </Stack>
  );
};

export default GameResult;
