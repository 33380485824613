import React, { useContext, useState } from "react";
import {
  Button,
  Group,
  Box,
  Progress,
  Stack,
  TextInput,
  Title,
  Text,
} from "@mantine/core";
import { areasOfLife } from "../../dto/areasOfLife";
import { useApi } from "../../useApi";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useLocation } from "react-router-dom";
import PseudoStep from "./PseudoStep";
import { UserContext } from "../../context/UserContext";
import MultiOptionSelector from "./MultiOptionSelector";
import StyledRadioGroup from "./StyledRadioGroup";
import { bigCelebrationEffect } from "../../utils/confettiEffects";

interface FormData {
  pseudo: string;
  gender: string;
  ageRange: string;
  relationshipStatus: string;
  hasChildren: string;
  areasOfInterest: string[];
  usedSimilarApps: string;
  personalGoal: string;
  dailyCommitment: string;
  goalMotivation: string;
}

const OnboardingForm = () => {
  const [active, setActive] = useState(0);
  const { user, setUser } = useContext(UserContext);
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isGoogleSignup = params.get("google") === "true";
  const [canProgress, setCanProgress] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    pseudo: "",
    gender: "",
    ageRange: "",
    relationshipStatus: "",
    hasChildren: "",
    areasOfInterest: [],
    usedSimilarApps: "",
    personalGoal: "",
    dailyCommitment: "",
    goalMotivation: "",
  });

  const totalSteps = 11; // Mise à jour du nombre total d'étapes
  const progress = ((active + 1) / totalSteps) * 100;

  const ageRanges = [
    { value: "18-24", label: "18-24 ans" },
    { value: "25-34", label: "25-34 ans" },
    { value: "35-44", label: "35-44 ans" },
    { value: "45-54", label: "45-54 ans" },
    { value: "55-64", label: "55-64 ans" },
    { value: "65+", label: "65 ans et plus" },
  ];

  const handlePseudoValidated = (pseudo: string) => {
    if (formData.pseudo !== pseudo) {
      setFormData((prev) => ({ ...prev, pseudo }));
    }
    setCanProgress(true);
  };

  const handlePseudoError = () => {
    setCanProgress(false);
  };

  // Ajout d'une fonction pour sauvegarder la progression
  const saveProgress = async (
    stepData: Partial<FormData>,
    currentStep: number
  ) => {
    try {
      // Créer un nouvel objet sans typage initial
      const processedData: { [key: string]: any } = { ...stepData };

      // Convertir les valeurs booléennes si nécessaire
      if ("usedSimilarApps" in processedData) {
        processedData.usedSimilarApps =
          processedData.usedSimilarApps === "true";
      }
      if ("dailyCommitment" in processedData) {
        processedData.dailyCommitment =
          processedData.dailyCommitment === "true";
      }
      if ("hasChildren" in processedData) {
        processedData.hasChildren = processedData.hasChildren === "true";
      }

      await api.post("/onboarding/progress", {
        currentStep,
        stepData: processedData,
      });
    } catch (error) {
      console.error("Failed to save progress", error);
    }
  };

  const handleOptionSelect = (field: keyof FormData, value: string) => {
    setFormData((prev) => {
      const newFormData = { ...prev, [field]: value };
      const nextStep = active < totalSteps - 1 ? active + 1 : active;

      // Sauvegarder la progression
      saveProgress({ [field]: value }, nextStep);

      setActive(nextStep);
      return newFormData;
    });
  };

  const nextStep = async () => {
    if (!canGoNext()) return;

    if (active === 0) {
      try {
        await api.post("/auth/update-pseudo", {
          pseudo: formData.pseudo,
        });
        if (user) {
          setUser({ ...user, pseudo: formData.pseudo });
        }
      } catch (error) {
        console.error("Failed to save pseudo", error);
        showNotification({
          title: "Erreur",
          message: "Impossible de sauvegarder le pseudo",
          color: "red",
        });
        return;
      }
    }

    // Pour l'étape des domaines d'intérêt
    if (active === 1) {
      await saveProgress(
        { areasOfInterest: formData.areasOfInterest },
        active + 1
      );
    }

    // Pour les étapes de texte libre
    if (active === 7) {
      await saveProgress({ personalGoal: formData.personalGoal }, active + 1);
    }
    if (active === 8) {
      await saveProgress(
        { goalMotivation: formData.goalMotivation },
        active + 1
      );
    }

    setActive((current) => (current < totalSteps - 1 ? current + 1 : current));
  };

  const handleSubmit = async () => {
    try {
      if (isGoogleSignup) {
        const response = await api.post("/auth/update-pseudo", {
          pseudo: formData.pseudo,
        });
        if (user) {
          setUser({ ...user, pseudo: response.data.pseudo });
        }
      }

      const { data } = await api.post("/onboarding", {
        userData: {
          gender: formData.gender,
          ageRange: formData.ageRange,
          relationshipStatus: formData.relationshipStatus,
          hasChildren: formData.hasChildren === "true",
          areasOfInterest: formData.areasOfInterest,
        },
        onboardingData: {
          usedSimilarApps: formData.usedSimilarApps === "true",
          personalGoal: formData.personalGoal,
          dailyCommitment: formData.dailyCommitment === "true",
          goalMotivation: formData.goalMotivation,
          completed: true, // Marquer comme complété
        },
      });

      setUser(data.user);
      showNotification({
        title: "Succès",
        message: "Vos informations ont été enregistrées avec succès",
        color: "green",
      });
      bigCelebrationEffect(); // Déclencher l'effet avant la redirection
      navigate("/");
    } catch (error) {
      showNotification({
        title: "Erreur",
        message: "Une erreur est survenue lors de l'enregistrement",
        color: "red",
      });
    }
  };

  const canGoNext = () => {
    switch (active) {
      case 0:
        return canProgress;
      case 1:
        return formData.areasOfInterest.length > 0;
      case 2:
        return !!formData.gender;
      case 3:
        return !!formData.ageRange;
      case 4:
        return !!formData.relationshipStatus;
      case 5:
        return !!formData.hasChildren;
      case 6:
        return !!formData.usedSimilarApps;
      case 7:
        return !!formData.personalGoal;
      case 8:
        return !!formData.goalMotivation;
      case 9:
        return !!formData.dailyCommitment;
      case 10:
        return true; // Toujours vrai pour l'étape finale
      default:
        return false;
    }
  };

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const renderStep = () => {
    switch (active) {
      case 0:
        return (
          <PseudoStep
            onPseudoValidated={handlePseudoValidated}
            onError={handlePseudoError}
            initialValue={formData.pseudo} // Passer la valeur actuelle du pseudo
          />
        );
      case 1:
        return (
          <MultiOptionSelector
            title="Choisissez 1 à 5 domaines à booster !"
            description="Chaque domaine vous rapproche d’une vie épanouie."
            options={areasOfLife.map((area) => ({
              value: area.value,
              label: area.title,
              icon: area.emoji,
              description: area.description,
            }))}
            value={formData.areasOfInterest}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, areasOfInterest: value }))
            }
            minSelection={1}
            maxSelection={5}
          />
        );
      case 2:
        return (
          <Stack>
            <StyledRadioGroup
              title="Votre genre"
              options={[
                { value: "MALE", label: "Homme" },
                { value: "FEMALE", label: "Femme" },
                { value: "OTHER", label: "Autre" },
              ]}
              value={formData.gender}
              onChange={(value) => handleOptionSelect("gender", value)}
            />
          </Stack>
        );
      case 3:
        return (
          <Stack>
            <StyledRadioGroup
              title="Votre tranche d'âge"
              options={ageRanges}
              value={formData.ageRange}
              onChange={(value) => handleOptionSelect("ageRange", value)}
            />
          </Stack>
        );
      case 4:
        return (
          <Stack>
            <StyledRadioGroup
              title="Êtes-vous en couple ?"
              options={[
                { value: "SINGLE", label: "Célibataire" },
                { value: "RELATIONSHIP", label: "En couple" },
                { value: "MARRIED", label: "Marié(e)" },
              ]}
              value={formData.relationshipStatus}
              onChange={(value) =>
                handleOptionSelect("relationshipStatus", value)
              }
            />
          </Stack>
        );
      case 5:
        return (
          <Stack>
            <StyledRadioGroup
              title="Avez-vous des enfants ?"
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              value={formData.hasChildren}
              onChange={(value) => handleOptionSelect("hasChildren", value)}
            />
          </Stack>
        );
      case 6:
        return (
          <Stack>
            <StyledRadioGroup
              title="Avez-vous déjà utilisé des apps de développement personnel ?"
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              value={formData.usedSimilarApps}
              onChange={(value) => handleOptionSelect("usedSimilarApps", value)}
            />
          </Stack>
        );
      case 7:
        return (
          <Stack>
            <Title order={2}>Définissez un objectif clair pour démarrer</Title>
            <TextInput
              placeholder="Ex. : 'Parler en public sans stress en 30 jours.'"
              value={formData.personalGoal}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  personalGoal: e.target.value,
                }))
              }
              size="md"
            />
          </Stack>
        );
      case 8:
        return (
          <Stack>
            <Title order={2}>Pourquoi cet objectif compte pour vous ?</Title>
            <TextInput
              placeholder="Dites-nous ce qui vous motive à réussir cet objectif."
              value={formData.goalMotivation}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  goalMotivation: e.target.value,
                }))
              }
              size="md"
            />
          </Stack>
        );
      case 9:
        return (
          <Stack>
            <StyledRadioGroup
              title="Prêt à donner 5 min/jour pour vous ?"
              description="C'est tout ce qu'il faut pour progresser !"
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              value={formData.dailyCommitment}
              onChange={(value) => handleOptionSelect("dailyCommitment", value)}
            />
          </Stack>
        );
      case 10:
        return (
          <Stack align="center" gap="xl">
            <Title order={1} ta="center">
              Félicitations, votre aventure commence maintenant !
            </Title>
            <Text size="lg" ta="center" c="dimmed">
              Mini-jeux, progrès, épanouissement : tout est prêt.
            </Text>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Box p="xl">
      <Group mb="lg" align="center" w="100%">
        <Box style={{ flex: 1 }}>
          <Progress value={progress} size="sm" />
        </Box>
        <Text
          size="sm"
          fw={500}
          style={{ whiteSpace: "nowrap" }}
          c={totalSteps === active + 1 ? "green" : undefined}
        >
          Étape {active + 1}
        </Text>
      </Group>
      {renderStep()}
      <Group justify="center" mt="xl">
        {active > 0 && active < totalSteps - 1 && (
          <Button variant="default" onClick={prevStep}>
            Retour
          </Button>
        )}
        {active === totalSteps - 1 ? (
          <Button onClick={handleSubmit} size="lg">
            Commencer
          </Button>
        ) : (
          <Button onClick={nextStep} disabled={!canGoNext()}>
            Suivant
          </Button>
        )}
      </Group>
    </Box>
  );
};

export default OnboardingForm;
