import React from "react";
import { Select } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { UserJourney } from "../dto/user";
import { getJourneyTitle } from "../utils/utils";

const JourneySelect = ({
  currentJourneyId,
  userJourneys,
  onJourneyChange,
}: {
  currentJourneyId: string | null;
  userJourneys: UserJourney[];
  onJourneyChange: (journeyId: number) => void;
}) => {
  const navigate = useNavigate();

  const selectData = [
    ...userJourneys.map((journey: UserJourney) => ({
      value: journey.journeyId ? journey.journeyId.toString() : "0",
      label: getJourneyTitle(journey.journey),
    })),
    {
      value: "add-new",
      label: "➕ Ajouter un parcours",
    },
  ];

  return (
    <Select
      placeholder="Sélectionner un parcours"
      value={currentJourneyId}
      onChange={(value) => {
        if (value === "add-new") {
          navigate("/select-journey");
        } else if (value) {
          onJourneyChange(parseInt(value, 10));
        }
      }}
      data={selectData}
      comboboxProps={{ withinPortal: true }}
      maw={180}
    />
  );
};

export default JourneySelect;
