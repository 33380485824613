import { Text, SimpleGrid, Container, rem } from "@mantine/core";
import {
  IconListCheck,
  IconChartBar,
  IconArrowUpRight,
} from "@tabler/icons-react";
import classes from "./FeaturesAsymmetrical.module.css";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: React.FC<any>;
  title: string;
  description: string;
}

function Feature({
  icon: Icon,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <Icon
          style={{ width: rem(38), height: rem(38) }}
          className={classes.icon}
          stroke={1.5}
        />
        <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.title}>
          {title}
        </Text>
        <Text c="dimmed" fz="sm">
          {description}
        </Text>
      </div>
    </div>
  );
}

const mockdata = [
  {
    icon: IconListCheck,
    title: "65 Compétences Essentielles",
    description:
      "Explore et développe les compétences clés réparties dans 8 domaines de vie : du social à la santé, en passant par les finances et le développement professionnel.",
  },
  {
    icon: IconChartBar,
    title: "Suivi de Progression Personnalisé",
    description:
      "Visualise ton évolution en temps réel grâce à des statistiques intuitives. Célèbre tes réussites et identifie tes prochains défis.",
  },
  {
    icon: IconArrowUpRight,
    title: "Une Expérience Qui Grandit Avec Toi",
    description:
      "Découvre régulièrement de nouveaux mini-jeux, des quêtes quotidiennes et du contenu frais pour enrichir ton parcours de développement.",
  },
];

export function Features({ width }: { width: number }) {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

  return (
    <Container mt={30} mb={30} size="lg" p={width > 800 ? undefined : 0}>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={50}>
        {items}
      </SimpleGrid>
    </Container>
  );
}
