import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextInput,
  Textarea,
  Title,
  Code,
  Text,
  LoadingOverlay,
  Select,
  Group,
} from "@mantine/core";
import { useApi } from "../useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSkills } from "../apiRoutes";
import { showNotification } from "@mantine/notifications";
import { Skill } from "../dto/user";

export const SYSTEM_PROMPT = `Tu es un expert en création de quiz éducatifs et pédagogiques. Ta mission est de générer un quiz de 10 questions en français sur le sujet fourni ci-dessous, en respectant le format suivant pour chaque question :

Question: [la question]
Options:
A) [option A]
B) [option B]
C) [option C] (optionnel)
D) [option D] (optionnel)
Réponse correcte: [A/B/C/D]
Explication: [explication]

Consignes de création :

Questions d'Acquisition (Questions 1 à 5) :
Introduction explicite du contenu : La première question doit présenter le principe fondamental tel que mentionné dans le contenu, en incluant explicitement le titre (par exemple, « Ne critiquez pas, ne condamnez pas, ne vous plaignez pas ») et la référence au chapitre ou au livre (par exemple, le chapitre n°1 de Comment se faire des amis par Dale Carnegie).
Présente les concepts clés et notions essentielles du contenu fourni.
Si le contenu comporte plusieurs principes ou chapitres, répartis-les sur les questions (par exemple, si trois principes sont évoqués, utilise les trois premières questions pour chacun d’eux).
Complète avec des informations pertinentes en développement personnel sur la notion si le contenu est incomplet, en t’assurant que toutes les informations nécessaires pour répondre aux questions de compréhension soient présentées ici.
Tu peux poser par exemple 1 ou 2 questions de reformulation pour vérifier la compréhension de la notion, particulièrement pour les principes clés.

Questions de Compréhension (Questions 6 à 10) :
Pour les questions 6 à 10, teste la capacité des participants à appliquer les principes dans des contextes variés ou nuancés. Formule des questions qui nécessitent une analyse ou une réflexion, en évitant les réponses immédiatement évidentes. Par exemple, propose des situations où l’application du principe pourrait sembler contre-intuitive.
Utilise en moyenne 3 options par question.
Évite d’introduire des connaissances externes : toutes les réponses doivent pouvoir être déduites des informations présentées dans les 5 premières questions.
Inclure au moins deux questions avec des mises en situation réalistes mais nuancées, où l’application du principe n’est pas immédiatement claire. Par exemple, propose des scénarios où appliquer le principe semble difficile, ou plusieurs approches pourraient sembler justifiées, mais une seule respecte pleinement le principe.

Niveau et Style :
Formule les questions de manière claire, concise et pédagogique.
Tu peux inclure 1 à 2 questions de type Vrai/Faux.
Fournis des explications détaillées pour chaque réponse qui font un lien avec le contenu fourni, afin que le participant puisse apprendre à partir des explications.
Adapte le niveau des questions pour qu’elles soient stimulantes et challengent un public intéressé par le développement personnel. Évite les réponses trop évidentes ou directes, en favorisant des formulations qui encouragent une réflexion critique.
Inclue une question qui explore les conséquences négatives de ne pas appliquer le principe (par exemple, les effets de la critique sur une relation), afin de souligner son importance.

Contexte et Fidélité au Contenu :
Assure-toi que chaque question, option et explication soit directement justifiable par le contenu fourni, sans introduire de concepts ou idées non mentionnés.
Réfère explicitement aux titres, principes et références (par exemple, le titre « Ne critiquez pas, ne condamnez pas, ne vous plaignez pas » et le chapitre de Dale Carnegie) dès la première question afin d’ancrer le contexte dès le départ.`;

interface QuizGeneratorProps {
  isModal?: boolean;
  initialTitle?: string;
  initialContent?: string;
  onClose?: () => void;
  knowledgeId?: number;
  existingMiniGame?: {
    id: number;
    title: string;
  } | null;
}

const QuizGenerator: React.FC<QuizGeneratorProps> = ({
  isModal = false,
  initialTitle = "",
  initialContent = "",
  onClose,
  knowledgeId,
  existingMiniGame,
}) => {
  const [systemPrompt, setSystemPrompt] = useState(SYSTEM_PROMPT);
  const [topic, setTopic] = useState("");
  const [title, setTitle] = useState("");
  const [skillId, setSkillId] = useState<string | null>(null);
  const [questions, setQuestions] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [isFormatting, setIsFormatting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const api = useApi();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (initialTitle || initialContent) {
      setTitle(initialTitle);
      setTopic(`Titre: ${initialTitle}
Contenu: ${initialContent}`);
    }
  }, [initialTitle, initialContent]);

  const {
    data: skills,
    isLoading: skillsLoading,
    error: skillsError,
  } = useQuery<Skill[], Error>({
    queryKey: ["skills"],
    queryFn: () => getSkills(api),
  });

  const handleGenerateQuestions = async () => {
    if (!topic) return;

    try {
      setIsGenerating(true);
      const response = await api.post("/quiz/generate", {
        systemPrompt,
        topic,
      });
      setQuestions(response.data.questions);
      showNotification({
        title: "Succès",
        message: "Questions générées avec succès",
        color: "green",
      });
    } catch (error) {
      console.error("Error generating questions:", error);
      showNotification({
        title: "Erreur",
        message: "Échec de la génération des questions",
        color: "red",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFormat = async () => {
    try {
      setIsFormatting(true);
      const response = await api.post("/quiz/format", { questions });
      setFormattedJson(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error formatting questions:", error);
      showNotification({
        title: "Erreur",
        message: "Échec du formatage des questions",
        color: "red",
      });
    } finally {
      setIsFormatting(false);
    }
  };

  const handleSave = async () => {
    if (!title || !formattedJson) {
      showNotification({
        title: "Erreur",
        message: "Veuillez remplir tous les champs requis",
        color: "red",
      });
      return;
    }

    try {
      setIsSaving(true);
      const formattedData = JSON.parse(formattedJson);
      const saveData = {
        title,
        questions: formattedData.questions,
      };

      let response;
      if (existingMiniGame) {
        // Update existing quiz
        response = await api.put(`/quiz/${existingMiniGame.id}`, saveData);
      } else {
        // Create new quiz
        response = await api.post("/quiz/save", {
          ...saveData,
          skillId: skillId ? parseInt(skillId) : null,
          ...(knowledgeId && { knowledgeId }),
        });
      }

      queryClient.invalidateQueries({ queryKey: ["journeyKnowledge"] });

      showNotification({
        title: "Succès",
        message: `Quiz ${existingMiniGame ? "mis à jour" : "sauvegardé"} avec ${
          response.data.questionsCount
        } questions`,
        color: "green",
      });

      if (isModal && onClose) {
        onClose();
      } else {
        setTopic("");
        setTitle("");
        setSkillId(null);
        setQuestions("");
        setFormattedJson("");
      }
    } catch (error) {
      console.error("Error saving quiz:", error);
      showNotification({
        title: "Erreur",
        message: `Échec de la ${
          existingMiniGame ? "mise à jour" : "sauvegarde"
        } du quiz`,
        color: "red",
      });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (existingMiniGame) {
      setTitle(existingMiniGame.title);
    }
  }, [existingMiniGame]);

  return (
    <Box p={isModal ? 0 : 16} pos="relative">
      <LoadingOverlay visible={isFormatting || isSaving || isGenerating} />
      {!isModal && <Title mb="xl">Générateur de Quiz</Title>}

      <Group grow align="flex-start">
        <Textarea
          label="Prompt système"
          placeholder="Prompt pour le modèle GPT"
          value={systemPrompt}
          onChange={(e) => setSystemPrompt(e.target.value)}
          minRows={5}
          autosize
        />
        <Textarea
          label="Sujet du quiz"
          placeholder="Entrez le sujet pour générer les questions"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          minRows={5}
          autosize
        />
      </Group>
      <Group justify="center" mt="md">
        <Button
          onClick={handleGenerateQuestions}
          loading={isGenerating}
          disabled={!topic || !systemPrompt}
        >
          Générer les questions
        </Button>
      </Group>

      <Textarea
        label="Questions"
        placeholder="Les questions générées apparaîtront ici"
        minRows={5}
        value={questions}
        onChange={(e) => setQuestions(e.target.value)}
        mt="md"
        autosize
      />

      <Button
        onClick={handleFormat}
        disabled={!questions}
        loading={isFormatting}
        mt="sm"
      >
        Formatter avec GPT-4
      </Button>

      <Box mt="md">
        <Text mb="sm">JSON Formatté</Text>
        <Code block>{formattedJson}</Code>
      </Box>

      <Group grow mt="md">
        <TextInput
          label="Titre du quiz"
          placeholder="Entrez le titre du quiz"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <Select
          label="Compétence"
          placeholder="Sélectionnez une compétence"
          value={skillId}
          onChange={setSkillId}
          data={
            skills?.map((skill) => ({
              value: skill.id?.toString() || "",
              label: `${skill.name} - ${skill.area?.value}`,
            })) || []
          }
          clearable
          searchable
          disabled={skillsLoading}
          error={skillsError?.message}
        />
      </Group>

      <Group mt="sm" justify="flex-end">
        {isModal && (
          <Button variant="light" onClick={onClose}>
            Annuler
          </Button>
        )}
        <Button
          onClick={handleSave}
          disabled={!formattedJson || !title}
          loading={isSaving}
          color="green"
        >
          {existingMiniGame ? "Mettre à jour le Quiz" : "Sauvegarder le Quiz"}
        </Button>
      </Group>
    </Box>
  );
};

export default QuizGenerator;
