import React, { useState } from "react";
import {
  Box,
  Button,
  Textarea,
  LoadingOverlay,
  Code,
  Group,
} from "@mantine/core";
import { useApi } from "../useApi";
import { showNotification } from "@mantine/notifications";

interface KnowledgeGeneratorProps {
  journeyId: string;
  onKnowledgeGenerated: () => void;
}

const KnowledgeGenerator: React.FC<KnowledgeGeneratorProps> = ({
  journeyId,
  onKnowledgeGenerated,
}) => {
  const [topic, setTopic] =
    useState(`Génère une liste de points de connaissance essentiels sur . 
Pour chaque point: Un titre clair et concis, un contenu détaillé et enrichissant en HTML / une progression logique du plus basique au plus avancé`);
  const [generatedContent, setGeneratedContent] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isFormatting, setIsFormatting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const api = useApi();

  const handleGenerate = async () => {
    if (!topic) return;

    try {
      setIsGenerating(true);
      const response = await api.post("/knowledge/generate", { topic });
      setGeneratedContent(response.data.content);
      showNotification({
        title: "Succès",
        message: "Contenu généré avec succès",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Erreur",
        message: "Échec de la génération du contenu",
        color: "red",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFormat = async () => {
    try {
      setIsFormatting(true);
      const response = await api.post("/knowledge/format", {
        content: generatedContent,
      });
      setFormattedJson(JSON.stringify(response.data, null, 2));
    } catch (error) {
      showNotification({
        title: "Erreur",
        message: "Échec du formatage du contenu",
        color: "red",
      });
    } finally {
      setIsFormatting(false);
    }
  };

  const handleSave = async () => {
    if (!formattedJson) return;

    try {
      setIsSaving(true);
      const { items } = JSON.parse(formattedJson);
      const currentOrder = (await api.get(`/knowledge/journey/${journeyId}`))
        .data.length;

      for (const knowledge of items) {
        const newKnowledge = await api.post("/knowledge", {
          title: knowledge.title,
          content: knowledge.content,
        });

        await api.post(`/knowledge/journey/${journeyId}`, {
          knowledgeId: newKnowledge.data.id,
          order: currentOrder + items.indexOf(knowledge),
        });
      }

      showNotification({
        title: "Succès",
        message: "Connaissances sauvegardées avec succès",
        color: "green",
      });

      setTopic("");
      setGeneratedContent("");
      setFormattedJson("");
      onKnowledgeGenerated();
    } catch (error) {
      showNotification({
        title: "Erreur",
        message: "Échec de la sauvegarde des connaissances",
        color: "red",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isGenerating || isFormatting || isSaving} />

      <Group align="flex-end">
        <Textarea
          label="Sujet"
          placeholder="Entrez le sujet pour générer le contenu"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          style={{ flex: 1 }}
          autosize
        />
        <Button
          onClick={handleGenerate}
          loading={isGenerating}
          disabled={!topic}
        >
          Générer
        </Button>
      </Group>

      <Textarea
        label="Contenu généré"
        placeholder="Le contenu généré apparaîtra ici"
        minRows={5}
        value={generatedContent}
        onChange={(e) => setGeneratedContent(e.target.value)}
        mt="md"
        autosize
      />

      <Button
        onClick={handleFormat}
        disabled={!generatedContent}
        loading={isFormatting}
        mt="sm"
      >
        Formatter
      </Button>

      {formattedJson && (
        <>
          <Box mt="md">
            <Code block>{formattedJson}</Code>
          </Box>

          <Button
            onClick={handleSave}
            disabled={!formattedJson}
            loading={isSaving}
            color="green"
            mt="sm"
          >
            Sauvegarder
          </Button>
        </>
      )}
    </Box>
  );
};

export default KnowledgeGenerator;
