/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  rem,
  useMantineTheme,
  Modal,
} from "@mantine/core";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
} from "@tabler/icons-react";
import classes from "./Header.module.css";
import { LoginRegister } from "./LoginRegister";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { displayUnused } from "../AppShell";

const mockdata = [
  {
    icon: IconCode,
    title: "Open source",
    description: "This Pokémon’s cry is very loud and distracting",
  },
  {
    icon: IconCoin,
    title: "Free tier",
    description: "The fluid of Smeargle’s tail secretions changes",
  },
  {
    icon: IconBook,
    title: "Documentation",
    description: "Yanma is capable of seeing 360 degrees without",
  },
  {
    icon: IconFingerprint,
    title: "Security",
    description: "The shell’s rounded shape and the grooves on its.",
  },
  {
    icon: IconChartPie3,
    title: "Analytics",
    description: "This Pokémon uses its flying ability to quickly chase",
  },
  {
    icon: IconNotification,
    title: "Notifications",
    description: "Combusken battles with the intensely hot flames it spews",
  },
];

export const headerLinks = (color: string) => {
  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group wrap="nowrap" align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon
            style={{ width: rem(22), height: rem(22) }}
            color={color}
          />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" c="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));
  return links;
};

export function Header({
  opened,
  close,
  toggle,
  typeLoginRegister,
  setLoginRegister,
  openedLoginRegister,
  openLoginRegister,
  closeLoginRegister,
}: {
  opened: boolean;
  close: () => void;
  toggle: () => void;
  typeLoginRegister: "login" | "register";
  setLoginRegister: React.Dispatch<React.SetStateAction<"login" | "register">>;
  openedLoginRegister: boolean;
  openLoginRegister: () => void;
  closeLoginRegister: () => void;
}) {
  const theme = useMantineTheme();
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const openRegister = () => {
      setLoginRegister("register");
      openLoginRegister();
    };
    window.addEventListener("register", openRegister);
    return () => {
      window.removeEventListener("register", openRegister);
    };
  }, [openLoginRegister, setLoginRegister]);

  return (
    <Box>
      <Modal
        opened={openedLoginRegister}
        onClose={closeLoginRegister}
        centered
        title={`Bienvenue chez Uply, ${
          typeLoginRegister === "login" ? "connect" : "inscriv"
        }ez vous`}
      >
        <LoginRegister
          type={typeLoginRegister}
          setState={setLoginRegister}
          close={closeLoginRegister}
        />
      </Modal>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Logo close={close} privateBeta />

          <Group h="100%" gap={0} visibleFrom="sm">
            {displayUnused && (
              <div onClick={() => navigate("/")} className={classes.link}>
                Home
              </div>
            )}
            {displayUnused && (
              <HoverCard
                width={600}
                position="bottom"
                radius="md"
                shadow="md"
                withinPortal
              >
                <HoverCard.Target>
                  <a href="#" className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={5}>
                        Features
                      </Box>
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                        color={theme.colors.theme[6]}
                      />
                    </Center>
                  </a>
                </HoverCard.Target>

                <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Features</Text>
                    <Anchor href="#" fz="xs">
                      View all
                    </Anchor>
                  </Group>

                  <Divider my="sm" />

                  <SimpleGrid cols={2} spacing={0}>
                    {headerLinks(theme.colors.theme[6])}
                  </SimpleGrid>

                  <div className={classes.dropdownFooter}>
                    <Group justify="space-between">
                      <div>
                        <Text fw={500} fz="sm">
                          Get started
                        </Text>
                        <Text size="xs" c="dimmed">
                          Their food sources have decreased, and their numbers
                        </Text>
                      </div>
                      <Button variant="default">Get started</Button>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            )}
            {displayUnused && (
              <a href="#" className={classes.link}>
                Learn
              </a>
            )}
            {displayUnused && (
              <a href="#" className={classes.link}>
                Pricing
              </a>
            )}
          </Group>

          <Group visibleFrom="sm">
            {!isLoggedIn && (
              <Button
                variant="default"
                onClick={() => {
                  openLoginRegister();
                  setLoginRegister("login");
                }}
              >
                Se connecter
              </Button>
            )}
            {!isLoggedIn && (
              <Button
                onClick={() => {
                  openLoginRegister();
                  setLoginRegister("register");
                }}
              >
                S'inscrire
              </Button>
            )}
          </Group>

          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
        </Group>
      </header>
    </Box>
  );
}
