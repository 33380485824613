import {
  UnstyledButton,
  Badge,
  Modal,
  Button,
  Stack,
  Text,
  // Text,
  // Group,
  rem,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconUser,
  // IconCheckbox,
  // IconUserShield,
  IconShield,
  IconTarget,
  IconChartBar,
  IconDeviceGamepad,
  IconHome,
  IconMessageCircle2,
  IconBrandDiscordFilled,
  IconRoad,
  // IconDeviceGamepad,
} from "@tabler/icons-react";
import classes from "./NavBar.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
// import { useAreasOfLife } from "../hooks/useAreasOfLife";
import Logo from "./Logo";
import { useMediaQuery } from "@mantine/hooks";

export function NavBar({ close }: { close: () => void }) {
  const { user } = useContext(UserContext);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const [discordModalOpened, setDiscordModalOpened] = useState(false);
  // const areasOfLifeExtended = useAreasOfLife(user, true);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const links = [
    {
      icon: IconHome,
      emoji: "🏠",
      label: "Accueil",
      notifications: 0,
      link: "/",
    },
    // {
    //   icon: IconDeviceGamepad,
    //   label: "Mini-Jeux",
    //   notifications: 0,
    //   link: "/mini-games",
    // },
    // { icon: IconTarget, label: "Quêtes", notifications: 0, link: "/quests" },
    // { icon: IconBulb, label: "Actions", notifications: 3, link: "/" },
    // { icon: IconCheckbox, label: "Tâches", notifications: 4, link: "/" },
    // { icon: IconChartBar, label: "Statistiques", link: "/statistics" },
    // { icon: IconUser, label: "Annuaire", link: "/users" },
    {
      icon: IconMessageCircle2,
      emoji: "💬",
      label: "Feedback",
      link: "/feedback",
    },
    {
      icon: IconBrandDiscordFilled,
      emoji: "💬",
      label: "Discord",
      link: "https://discord.gg/Mu6JDUp7HA",
    },
    // { icon: IconSettings, emoji: "⚙️", label: "Paramètres", link: "/settings" },
    { icon: IconRoad, emoji: "🗺️", label: "Parcours", link: "/select-journey" },
    // { icon: IconDeviceGamepad, label: "Jeux", link: "/mini-game" },
  ];

  if (user?.id && user?.id < 20 && user?.id !== 17) {
    links.push({
      icon: IconTarget,
      emoji: "🎯",
      label: "Quêtes",
      notifications: 0,
      link: "/quests",
    });
    links.push({
      icon: IconDeviceGamepad,
      emoji: "🎮",
      label: "Mini-Jeux",
      notifications: 0,
      link: "/mini-games",
    });
  }

  if (user?.id && user?.id <= 6) {
    links.push({
      icon: IconUser,
      emoji: "🧠",
      label: "Croissance",
      link: "/brain",
    });
  }

  if (user?.role === "admin") {
    // links.push({
    //   icon: IconUserShield,
    //   label: "Admin Skills",
    //   link: "/admin-skills",
    // });
    // links.push({
    //   icon: IconUserShield,
    //   label: "Admin Quests",
    //   link: "/admin-quests",
    // });
    // links.push({
    //   icon: IconUserShield,
    //   label: "Admin Feedbacks",
    //   link: "/admin-feedbacks",
    // });
    // links.push({
    //   icon: IconUserShield,
    //   label: "Admin MiniGames",
    //   link: "/admin-mini-games",
    // });
    // links.push({
    //   icon: IconUserShield,
    //   label: "MiniGame #1",
    //   link: "/mini-game/1",
    // });
    // links.push({
    //   icon: IconUserShield,
    //   label: "MiniGame #4",
    //   link: "/mini-game/4",
    // });
    links.push({
      icon: IconChartBar,
      emoji: "📊",
      label: "Statistiques",
      link: "/statistics",
    });
    links.push({
      icon: IconUser,
      emoji: "👤",
      label: "Annuaire",
      link: "/users",
    });
    links.push({
      icon: IconShield,
      emoji: "🛡️",
      label: "Admin",
      link: "/admin",
    });
  }

  const mainLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      className={classes.mainLink}
      onClick={() => {
        if (link.label === "Discord") {
          setDiscordModalOpened(true);
        } else {
          navigate(link.link);
          close();
        }
      }}
      p="sm"
    >
      <div className={classes.mainLinkInner} style={{ fontSize: 14 }}>
        <span className={classes.mainLinkIcon}>
          {link.label === "Discord" ? (
            <link.icon size={20} style={{ color: "#5865F2" }} />
          ) : (
            <span style={{ lineHeight: 1 }}>{link.emoji}</span>
          )}
        </span>
        <span>{link.label}</span>
      </div>
      {link.notifications !== 0 && link.notifications && (
        <Badge size="sm" variant="filled" circle>
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ));

  // const collectionLinks = areasOfLifeExtended.map((collection) => (
  //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
  //   <a
  //     href="#"
  //     onClick={(event) => {
  //       event.preventDefault();
  //       navigate(`/areas/${collection.value}`);
  //       close();
  //     }}
  //     key={collection.title}
  //     className={classes.collectionLink}
  //   >
  //     <span style={{ marginRight: rem(9), fontSize: rem(16) }}>
  //       {collection.emoji}
  //     </span>{" "}
  //     {collection.title}
  //   </a>
  // ));

  return (
    <>
      <Modal
        opened={discordModalOpened}
        onClose={() => setDiscordModalOpened(false)}
        title="Rejoindre le Discord"
        centered
      >
        <Stack>
          <Text>Voulez-vous rejoindre notre communauté Discord ?</Text>
          <Button
            onClick={() => {
              window.open("https://discord.gg/Mu6JDUp7HA", "_blank");
              setDiscordModalOpened(false);
            }}
            fullWidth
            color="#5865F2"
          >
            Rejoindre le Discord
          </Button>
        </Stack>
      </Modal>

      <nav className={classes.navbar}>
        {!isMobile && (
          <div
            className={classes.section}
            style={{
              borderBottom: `1px solid ${
                colorScheme === "light"
                  ? theme.colors.gray[3]
                  : theme.colors.dark[4]
              }`,
              paddingBottom: rem(16),
              paddingLeft: rem(16),
            }}
          >
            <Logo close={close} privateBeta />
          </div>
        )}

        <div
          className={classes.section}
          // style={{
          //   borderBottom: `1px solid ${
          //     colorScheme === "light"
          //       ? theme.colors.gray[3]
          //       : theme.colors.dark[4]
          //   }`,
          // }}
        >
          <div className={classes.mainLinks}>{mainLinks}</div>
        </div>

        {/* <div className={classes.section}>
          <Group className={classes.collectionsHeader} justify="space-between">
            <Text
              size="xs"
              fw={500}
              c="dimmed"
              onClick={() => {
                navigate("/domains");
                close();
              }}
              style={{ cursor: "pointer" }}
            >
              Domaines
            </Text>
          </Group>
          <div className={classes.collections}>{collectionLinks}</div>
        </div> */}
      </nav>
    </>
  );
}
