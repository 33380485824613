import React from "react";
import { Modal, Button, Group, Text } from "@mantine/core";
import { Journey } from "../dto/user";
import { getJourneyTitle } from "../utils/utils";
import DOMPurify from "dompurify";

const ConfirmJourneyModal = ({
  isOpen,
  onClose,
  onConfirm,
  journey,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  journey: Journey;
  isLoading: boolean;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={`Démarrer le parcours ${getJourneyTitle(journey)}`}
      size="md"
      centered
    >
      <Text mb="md">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(journey.description || ""),
          }}
        />
      </Text>
      <Group justify="space-between">
        <Button variant="light" onClick={onClose}>
          Annuler
        </Button>
        <Button onClick={onConfirm} loading={isLoading} color="violet">
          Commencer le parcours
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmJourneyModal;
