// useAreasOfLife.ts
import { useMemo } from "react";
import { User } from "../dto/user";
import { areasOfLife, areasOfLifeExtended } from "../dto/areasOfLife";

export interface AreaOfLife {
  id: number;
  title: string;
  value: string;
  emoji: string;
  backgroundImage: string;
  description: string;
  precision: string;
}

export function useAreasOfLife(user: User | null, extended: boolean = false) {
  return useMemo(() => {
    // Si pas d'utilisateur, retourner une liste vide ou la liste complète selon le cas d'usage
    if (!user) {
      return extended ? areasOfLifeExtended : areasOfLife;
    }

    // Filtrer les domaines selon le statut de l'utilisateur
    const filteredAreas = (extended ? areasOfLifeExtended : areasOfLife).filter(
      (area) => {
        // Toujours garder le domaine "Actifs" si on est en mode extended
        if (area.value === "all") return true;

        // Filtrer le domaine Couple selon le statut relationnel
        if (area.value === "couple") {
          return (
            user.relationshipStatus === "RELATIONSHIP" ||
            user.relationshipStatus === "MARRIED"
          );
        }

        // Filtrer le domaine Famille selon le statut parental
        if (area.value === "family") {
          return user.hasChildren === true;
        }

        // Garder tous les autres domaines
        return true;
      }
    );

    return filteredAreas;
  }, [user, extended]);
}
