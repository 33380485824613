import React, { useState, useEffect } from "react";
import {
  Paper,
  Text,
  TextInput,
  Button,
  Group,
  Box,
  Modal,
  Badge,
  Switch,
} from "@mantine/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import RichTextEditorField from "./RichTextEditorField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { IconGripVertical } from "@tabler/icons-react";
import QuizGenerator, { SYSTEM_PROMPT } from "../Pages/QuizGenerator";
import { showNotification } from "@mantine/notifications";
import QuizQuestionsModal from "./QuizQuestionModal";
import { useQuery } from "@tanstack/react-query";

interface Props {
  id: number;
  title: string;
  content: string;
  journeyId: string | undefined;
  miniGame: {
    id: number;
    title: string;
  } | null;
  isEvaluation?: boolean;
}

const SortableKnowledgeItem = ({
  id,
  title,
  content,
  journeyId,
  miniGame,
  isEvaluation = false,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedContent, setEditedContent] = useState(content);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [deleteMiniGameModalOpen, setDeleteMiniGameModalOpen] = useState(false);
  const [isAutoGenerating, setIsAutoGenerating] = useState(false);
  const [autoGenerateModalOpen, setAutoGenerateModalOpen] = useState(false);
  const [viewQuestionsModalOpen, setViewQuestionsModalOpen] = useState(false);
  const [editedIsEvaluation, setEditedIsEvaluation] = useState(isEvaluation);

  const api = useApi();
  const queryClient = useQueryClient();

  useEffect(() => {
    setEditedTitle(title);
    setEditedContent(content);
    setEditedIsEvaluation(isEvaluation);
  }, [title, content, isEvaluation]);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { data: quizQuestions } = useQuery({
    queryKey: ["quizQuestions", miniGame?.id],
    queryFn: () =>
      api.get(`/mini-games/${miniGame?.id}/questions`).then((res) => res.data),
    enabled: !!miniGame?.id && viewQuestionsModalOpen,
  });

  const handleAutoGenerateQuiz = async () => {
    try {
      setIsAutoGenerating(true);
      setAutoGenerateModalOpen(false);

      // Step 1: Generate questions
      const topic = `Titre: ${title}\nContenu: ${content}`;
      const generatedQuestions = await api.post("/quiz/generate", {
        systemPrompt: SYSTEM_PROMPT,
        topic,
      });

      // Step 2: Format questions
      const formattedResponse = await api.post("/quiz/format", {
        questions: generatedQuestions.data.questions,
      });

      // Step 3: Update existing quiz or create new one
      const saveData = {
        title,
        questions: formattedResponse.data.questions,
        knowledgeId: id,
      };

      if (miniGame) {
        // Update existing quiz
        await api.put(`/quiz/${miniGame.id}`, saveData);
      } else {
        // Create new quiz
        await api.post("/quiz/save", saveData);
      }

      queryClient.invalidateQueries({
        queryKey: ["journeyKnowledge", journeyId],
      });

      showNotification({
        title: "Succès",
        message: `Quiz ${
          miniGame ? "mis à jour" : "généré et sauvegardé"
        } avec succès`,
        color: "green",
      });
    } catch (error) {
      console.error("Error auto-generating quiz:", error);
      showNotification({
        title: "Erreur",
        message: "Échec de la génération automatique du quiz",
        color: "red",
      });
    } finally {
      setIsAutoGenerating(false);
    }
  };

  const updateMutation = useMutation({
    mutationFn: (data: {
      title: string;
      content: string;
      isEvaluation: boolean;
    }) => api.put(`/knowledge/${id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["journeyKnowledge", journeyId],
      });
      setIsEditing(false);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => api.delete(`/knowledge/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["journeyKnowledge", journeyId],
      });
      setDeleteModalOpen(false);
    },
  });

  const deleteMiniGameMutation = useMutation({
    mutationFn: () => api.delete(`/mini-games/${miniGame?.id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["journeyKnowledge", journeyId],
      });
      setDeleteMiniGameModalOpen(false);
    },
  });

  const handleSave = () => {
    updateMutation.mutate({
      title: editedTitle,
      content: editedContent,
      isEvaluation: editedIsEvaluation,
    });
  };

  return (
    <>
      <Paper
        ref={setNodeRef}
        style={style}
        p="md"
        mb="sm"
        withBorder
        shadow="sm"
        bg={isEvaluation ? "rgba(34, 197, 94, 0.2)" : undefined}
      >
        <Group justify="space-between" mb="xs" wrap="nowrap">
          <Box
            {...attributes}
            {...listeners}
            style={{
              cursor: "grab",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <IconGripVertical size={24} style={{ opacity: 0.5 }} />
          </Box>

          <Box style={{ flex: 1 }}>
            {isEditing ? (
              <>
                <TextInput
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  placeholder="Title"
                  mb="xs"
                />
                <Switch
                  label="Est une évaluation"
                  checked={editedIsEvaluation}
                  onChange={(event) =>
                    setEditedIsEvaluation(event.currentTarget.checked)
                  }
                  color="green"
                />
              </>
            ) : (
              <Group gap="xs" align="center">
                <Text size="lg" fw={500}>
                  {title}
                </Text>
                {miniGame && <Badge color="blue">Quiz créé</Badge>}
                {isEvaluation && (
                  <Badge color="green" variant="light" size="lg">
                    Évaluation
                  </Badge>
                )}
              </Group>
            )}
          </Box>

          <Group>
            {miniGame ? (
              <Group>
                <Button
                  variant="light"
                  color="gray"
                  size="xs"
                  onClick={() => setViewQuestionsModalOpen(true)}
                >
                  Voir les questions
                </Button>
                <Button
                  variant="light"
                  color="blue"
                  size="xs"
                  onClick={() => setQuizModalOpen(true)}
                >
                  Recréer le Quiz
                </Button>
                <Button
                  variant="light"
                  color="blue"
                  size="xs"
                  onClick={() => setAutoGenerateModalOpen(true)}
                  loading={isAutoGenerating}
                >
                  Regénérer Auto
                </Button>
                <Button
                  variant="light"
                  color="red"
                  size="xs"
                  onClick={() => setDeleteMiniGameModalOpen(true)}
                >
                  Supprimer le Quiz
                </Button>
              </Group>
            ) : (
              <Group>
                <Button
                  variant="light"
                  size="xs"
                  onClick={() => setQuizModalOpen(true)}
                >
                  Créer un Quiz
                </Button>
                <Button
                  variant="light"
                  size="xs"
                  onClick={() => setAutoGenerateModalOpen(true)}
                  loading={isAutoGenerating}
                  color="blue"
                >
                  Générer Quiz Auto
                </Button>
              </Group>
            )}
            <Button
              variant="light"
              size="xs"
              onClick={() => {
                if (isEditing) {
                  handleSave();
                } else {
                  setIsEditing(true);
                }
              }}
              loading={updateMutation.isPending}
            >
              {isEditing ? "Save" : "Edit"}
            </Button>
            {isEditing ? (
              <Button
                variant="subtle"
                color="red"
                size="xs"
                onClick={() => {
                  setEditedTitle(title);
                  setEditedContent(content);
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="subtle"
                color="red"
                size="xs"
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete
              </Button>
            )}
          </Group>
        </Group>

        <Box mt="md">
          {isEditing ? (
            <RichTextEditorField
              content={editedContent}
              onChange={setEditedContent}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </Box>
      </Paper>

      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Confirm Deletion"
      >
        <Text>Are you sure you want to delete this knowledge item?</Text>
        <Group justify="flex-end" mt="md">
          <Button variant="light" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => deleteMutation.mutate()}
            loading={deleteMutation.isPending}
          >
            Delete
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={deleteMiniGameModalOpen}
        onClose={() => setDeleteMiniGameModalOpen(false)}
        title="Supprimer le Quiz"
      >
        <Text>Êtes-vous sûr de vouloir supprimer ce quiz ?</Text>
        <Group justify="flex-end" mt="md">
          <Button
            variant="light"
            onClick={() => setDeleteMiniGameModalOpen(false)}
          >
            Annuler
          </Button>
          <Button
            color="red"
            onClick={() => deleteMiniGameMutation.mutate()}
            loading={deleteMiniGameMutation.isPending}
          >
            Supprimer
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={autoGenerateModalOpen}
        onClose={() => setAutoGenerateModalOpen(false)}
        title="Générer un Quiz Automatiquement"
      >
        <Text>
          {miniGame
            ? "Êtes-vous sûr de vouloir régénérer automatiquement le quiz ? L'ancien quiz sera remplacé."
            : "Êtes-vous sûr de vouloir générer automatiquement un quiz ?"}
        </Text>
        <Group justify="flex-end" mt="md">
          <Button
            variant="light"
            onClick={() => setAutoGenerateModalOpen(false)}
          >
            Annuler
          </Button>
          <Button
            color="blue"
            onClick={handleAutoGenerateQuiz}
            loading={isAutoGenerating}
          >
            {miniGame ? "Régénérer" : "Générer"}
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={quizModalOpen}
        onClose={() => setQuizModalOpen(false)}
        title={miniGame ? "Recréer le Quiz" : "Créer un Quiz"}
        size="100%"
      >
        <QuizGenerator
          isModal={true}
          initialTitle={title}
          initialContent={content}
          onClose={() => setQuizModalOpen(false)}
          knowledgeId={id}
          existingMiniGame={miniGame}
        />
      </Modal>

      <QuizQuestionsModal
        opened={viewQuestionsModalOpen}
        onClose={() => setViewQuestionsModalOpen(false)}
        questions={quizQuestions || []}
      />
    </>
  );
};

export default SortableKnowledgeItem;
