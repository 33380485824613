import { Button, Modal, Text, Group, ActionIcon } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface QuitGameModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

export const QuitGameModal: React.FC<QuitGameModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const navigate = useNavigate();

  const handleConfirmQuit = () => {
    navigate("/");
  };

  return (
    <>
      <ActionIcon
        variant="subtle"
        color="gray"
        style={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 1000,
        }}
        onClick={() => setIsModalOpen(true)}
      >
        <IconX size="1.2rem" />
      </ActionIcon>

      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Quitter le mini-jeu ?"
        size="sm"
        centered
      >
        <Text mb="xl">
          Attention, si vous quittez maintenant, vous perdrez toute progression
          dans ce mini-jeu.
        </Text>
        <Group justify="flex-end">
          <Button variant="light" onClick={() => setIsModalOpen(false)}>
            Annuler
          </Button>
          <Button color="red" onClick={handleConfirmQuit}>
            Quitter
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default QuitGameModal;
