import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Types pour les données
interface Competence {
  name: string;
  level: number; // 0 à 10
  importance: number; // 0 à 10
}

interface Domain {
  name: string;
  competencies: Competence[];
}

interface BeautifulLifeTreeProps {
  domains?: Domain[];
}

const BeautifulLifeTree: React.FC<BeautifulLifeTreeProps> = ({ domains }) => {
  const mountRef = useRef<HTMLDivElement>(null);

  // Données mockées pour tester
  const mockDomains: Domain[] = Array(5)
    .fill(null)
    .map((_, index) => ({
      name: `Domaine ${index + 1}`,
      competencies: Array(4)
        .fill(null)
        .map((_, i) => ({
          name: `Comp ${i + 1}`,
          level: Math.floor(Math.random() * 11),
          importance: Math.floor(Math.random() * 11),
        })),
    }));

  const usedDomains = domains || mockDomains;

  useEffect(() => {
    if (!mountRef.current) return;

    // Scène, caméra, renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x87ceeb); // Fond ciel bleu clair
    mountRef.current.appendChild(renderer.domElement);

    // Contrôles pour pivoter
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Lumière
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 10, 7.5);
    scene.add(directionalLight);

    // Tronc robuste
    const trunkGeometry = new THREE.CylinderGeometry(0.3, 1, 8, 32); // Fin en haut, large en bas
    const trunkMaterial = new THREE.MeshLambertMaterial({ color: 0x5c4033 }); // Brun boisé
    const trunk = new THREE.Mesh(trunkGeometry, trunkMaterial);
    trunk.position.y = 4; // Centré verticalement
    scene.add(trunk);

    // Branches principales et sous-branches
    usedDomains.forEach((domain, domainIndex) => {
      const branchAngle = (domainIndex / usedDomains.length) * 2 * Math.PI;
      const branchHeight = 2 + domainIndex * 1.2; // Espacement vertical

      // Branche principale
      const branchGeometry = new THREE.CylinderGeometry(0.1, 0.2, 4, 16);
      const branchMaterial = new THREE.MeshLambertMaterial({ color: 0x4a2f1a });
      const branch = new THREE.Mesh(branchGeometry, branchMaterial);
      branch.position.set(
        Math.sin(branchAngle) * 2,
        branchHeight,
        Math.cos(branchAngle) * 2
      );
      branch.rotation.z = branchAngle + Math.PI / 3; // Inclinaison naturelle
      scene.add(branch);

      // Sous-branches et feuilles
      domain.competencies.forEach((comp, compIndex) => {
        const subBranchLength = 1.5 + compIndex * 0.5;
        const subBranchGeometry = new THREE.CylinderGeometry(
          0.05,
          0.08,
          subBranchLength,
          12
        );
        const subBranch = new THREE.Mesh(subBranchGeometry, branchMaterial);
        subBranch.position.set(
          branch.position.x + Math.sin(branchAngle) * 1,
          branch.position.y + compIndex * 0.5,
          branch.position.z + Math.cos(branchAngle) * 1
        );
        subBranch.rotation.z = branchAngle + Math.PI / 4;
        scene.add(subBranch);

        // Feuille
        const leafSize = (comp.level / 10) * 0.3 + 0.1; // 0.1 à 0.4
        const leafGeometry = new THREE.SphereGeometry(leafSize, 16, 16);
        const leafColor = comp.importance > 7 ? 0xffd700 : 0x228b22; // Doré ou vert
        const leafMaterial = new THREE.MeshLambertMaterial({
          color: leafColor,
        });
        const leaf = new THREE.Mesh(leafGeometry, leafMaterial);
        leaf.position.set(
          subBranch.position.x + Math.sin(branchAngle) * subBranchLength * 0.8,
          subBranch.position.y + subBranchLength * 0.5,
          subBranch.position.z + Math.cos(branchAngle) * subBranchLength * 0.8
        );
        scene.add(leaf);
      });
    });

    // Sol (optionnel)
    const groundGeometry = new THREE.CircleGeometry(5, 32);
    const groundMaterial = new THREE.MeshLambertMaterial({ color: 0x7cfc00 });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    scene.add(ground);

    camera.position.set(0, 5, 10);
    camera.lookAt(0, 4, 0);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Nettoyage
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mountRef.current?.removeChild(renderer.domElement);
    };
  }, [usedDomains]);

  return <div ref={mountRef} style={{ width: "100vw", height: "100vh" }} />;
};

export default BeautifulLifeTree;
