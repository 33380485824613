import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Button,
  Stack,
  Paper,
  LoadingOverlay,
  Box,
  Alert,
  Center,
  Progress,
  Group,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useApi } from "../../useApi";
import ReactMarkdown from "react-markdown";
import { useMutation } from "@tanstack/react-query";
import { IconCheck, IconX } from "@tabler/icons-react";
import { userLocalDate } from "../../utils/userLocalDate";
import GameResult from "./GameResult";
import { useQuizQuestions } from "../../hooks/useQuizQuestions";
import StyledRadioGroup from "../onboarding/StyledRadioGroup";
import { MiniGame } from "../../dto/user";

interface QuizGameProps {
  gameData: MiniGame;
  gameState: "not_started" | "started" | "finished" | "submitted";
  setGameState: React.Dispatch<
    React.SetStateAction<"not_started" | "started" | "finished" | "submitted">
  >;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number | undefined>>;
  hasTimeLimit: boolean;
}

interface QuizResult {
  score: number;
  totalQuestions: number;
  quizAnswers: {
    questionId: number;
    userAnswer: string;
    correctAnswer: string;
    isCorrect: boolean;
  }[];
}

const QuizGame: React.FC<QuizGameProps> = ({
  gameData,
  gameState,
  setGameState,
  timeLeft,
  setTimeLeft,
  hasTimeLimit,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<number, string>
  >({});
  const [quizResult, setQuizResult] = useState<QuizResult | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentStreak, setCurrentStreak] = useState(0);
  const api = useApi();

  const { questions, isLoading, shuffledOptionsMap, parseAnswers } =
    useQuizQuestions(gameData.id ?? 0, api);

  const submitMutation = useMutation<
    QuizResult,
    Error,
    {
      answers: Record<number, string>;
      timeSpent: number;
      userLocalDate: { year: number; month: number; day: number };
    }
  >({
    mutationFn: (data) =>
      api
        .post(`/mini-games/${gameData.id}/submit`, data)
        .then((res) => res.data),
    onSuccess: (data) => {
      setQuizResult(data);
      setGameState("submitted");
      setIsSubmitting(false);
    },
    onError: (error) => {
      notifications.show({
        title: "Erreur",
        message: "Impossible de soumettre les réponses du quiz.",
        color: "red",
      });
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    if (gameState === "started" && startTime === null) {
      setStartTime(Date.now());
    }
  }, [gameState, startTime]);

  useEffect(() => {
    if (gameState === "started" && hasTimeLimit && timeLeft === 0) {
      handleFinishGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, gameState, hasTimeLimit]);

  const handleAnswerSelect = (questionId: number, answer: string) => {
    if (!showAnswer) {
      setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
    }
  };

  const handleValidateAnswer = () => {
    setShowAnswer(true);
    if (selectedAnswers[currentQuestion.id] === parsedAnswers?.correctAnswer) {
      setCurrentStreak((prev) => prev + 1);
    } else {
      setCurrentStreak(0);
    }
  };

  const handleNextQuestion = () => {
    if (questions && currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setShowAnswer(false);
    } else {
      handleFinishGame();
    }
  };

  const submitGame = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const timeSpent = startTime
      ? Math.min(
          Math.floor((Date.now() - startTime) / 1000),
          gameData.timeLimit ?? 0
        )
      : gameData.timeLimit;
    submitMutation.mutate({
      answers: selectedAnswers,
      timeSpent: timeSpent ?? 0,
      userLocalDate: userLocalDate(),
    });
  }, [
    isSubmitting,
    startTime,
    gameData.timeLimit,
    selectedAnswers,
    submitMutation,
  ]);

  const handleFinishGame = useCallback(() => {
    setGameState("finished");
    submitGame();
  }, [setGameState, submitGame]);

  useEffect(() => {
    if (gameState === "finished" && !isSubmitting) {
      submitGame();
    }
  }, [gameState, isSubmitting, submitGame]);

  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }

  if ((gameState === "finished" || gameState === "submitted") && quizResult) {
    return (
      <Center>
        <GameResult
          title="Quiz terminé !"
          score={quizResult.score}
          totalQuestions={quizResult.totalQuestions}
          miniGame={gameData}
        />
      </Center>
    );
  }

  if (!questions || questions.length === 0) {
    return <Text>Aucune question n'est disponible pour ce quiz.</Text>;
  }

  const currentQuestion = questions[currentQuestionIndex];
  if (!currentQuestion) {
    return <Text>Erreur: La question actuelle n'est pas disponible.</Text>;
  }

  const parsedAnswers = parseAnswers(currentQuestion.answers);
  if (!parsedAnswers) {
    return (
      <Text>Erreur: Les réponses pour cette question sont mal formatées.</Text>
    );
  }

  const isAnswerSelected = selectedAnswers[currentQuestion.id] !== undefined;
  const shuffledOptions = shuffledOptionsMap[currentQuestion.id];
  const userAnswer = selectedAnswers[currentQuestion.id];
  const correctAnswer = parsedAnswers.correctAnswer;
  const isCorrect = userAnswer === correctAnswer;

  if (!shuffledOptions) {
    return (
      <Text>Erreur: Les réponses pour cette question sont mal formatées.</Text>
    );
  }

  return (
    <Stack>
      <Paper>
        <Group justify="space-between" h={20} align="center">
          <Progress
            value={(currentQuestionIndex + 1) * (100 / questions.length)}
            size="lg"
            radius="xl"
            color="green"
            style={{ flex: 1 }}
          />
          {showAnswer && currentStreak > 1 && (
            <Text
              c="green"
              fw={700}
              mt="0"
              style={{
                lineHeight: "20px",
              }}
            >
              {currentStreak} D'AFFILÉE
            </Text>
          )}
        </Group>
        <StyledRadioGroup
          title={currentQuestion.questionFr}
          options={shuffledOptions.map(({ option, value }) => ({
            label: option,
            value: value,
          }))}
          value={selectedAnswers[currentQuestion.id] || ""}
          onChange={(value) => handleAnswerSelect(currentQuestion.id, value)}
        />
      </Paper>

      {showAnswer && (
        <Box>
          {isCorrect ? (
            <Alert
              icon={<IconCheck size="1rem" />}
              title="Correct !"
              color="green"
            >
              {/* <Text mb="xs">Bonne réponse !</Text> */}
              <ReactMarkdown>{currentQuestion.explanationFr}</ReactMarkdown>
            </Alert>
          ) : (
            <Alert icon={<IconX size="1rem" />} title="Incorrect" color="red">
              <Text mb="xs">Explication :</Text>
              <ReactMarkdown>{currentQuestion.explanationFr}</ReactMarkdown>
            </Alert>
          )}
        </Box>
      )}

      <Center>
        {!showAnswer ? (
          <Button
            fullWidth
            onClick={handleValidateAnswer}
            disabled={!isAnswerSelected}
            size="lg"
          >
            Valider
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={handleNextQuestion}
            color={isCorrect ? "green" : "red"}
            size="lg"
          >
            {isCorrect ? "Continuer" : "D'accord"}
          </Button>
        )}
      </Center>
    </Stack>
  );
};

export default QuizGame;
