import {
  Box,
  Title,
  Table,
  Group,
  Button,
  Modal,
  TextInput,
  Textarea,
  Select,
  Image,
  Switch,
  JsonInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Skill } from "../dto/user";
import { useApi } from "../useApi";
import { getSkills, createEditSkill } from "../apiRoutes";
import { useForm } from "@mantine/form";
import { areasOfLife } from "../dto/areasOfLife";
import RichTextEditorField from "../Components/RichTextEditorField";
import { validateRatingTableJSON } from "../utils/ratingTableValidation";

const AdminSkills = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [modalOpened, setModalOpened] = useState(false);
  const [editingSkill, setEditingSkill] = useState<Skill | null>(null);

  const {
    data: skills,
    isLoading,
    error,
  } = useQuery<Skill[], Error>({
    queryKey: ["skills"],
    queryFn: () => getSkills(api),
  });

  const createEditSkillMutation = useMutation({
    mutationFn: (skill: Omit<Skill, "id"> | Skill) =>
      createEditSkill(api, skill),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["skills"] });
      setModalOpened(false);
      form.reset();
      setEditingSkill(null);
    },
  });

  const form = useForm({
    initialValues: {
      id: 0,
      name: "",
      backgroundImgUrl: "",
      content: "",
      ratingTableJSON: "",
      areaOfLifeId: "",
      activated: false,
      description: "",
    },
    validate: {
      name: (value) => (value.length > 0 ? null : "Name is required"),
      areaOfLifeId: (value) => (value ? null : "Area of Life is required"),
      description: (value) =>
        value.length > 0 ? null : "Description is required",
      ratingTableJSON: validateRatingTableJSON,
    },
  });

  const handleOpenModal = (skill?: Skill) => {
    if (skill) {
      setEditingSkill(skill);
      form.setValues({
        id: skill.id,
        name: skill.name,
        backgroundImgUrl: skill.backgroundImgUrl,
        content: skill.content,
        ratingTableJSON: skill.ratingTableJSON,
        areaOfLifeId: skill.area?.id?.toString() || "",
        activated: skill.activated,
        description: skill.description,
      });
    } else {
      setEditingSkill(null);
      form.reset();
    }
    setModalOpened(true);
  };

  const handleSubmit = (values: typeof form.values) => {
    const skillData: Partial<Skill> = {
      name: values.name,
      backgroundImgUrl: values.backgroundImgUrl,
      content: values.content,
      ratingTableJSON: values.ratingTableJSON,
      areaOfLifeId: parseInt(values.areaOfLifeId, 10),
      activated: values.activated,
      description: values.description,
    };
    if (editingSkill) {
      skillData.id = editingSkill.id;
    }
    createEditSkillMutation.mutate(skillData as Skill);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Box p={16}>
      <Group mb={16}>
        <Title>Admin Skills</Title>
        <Button onClick={() => handleOpenModal()}>Create New Skill</Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Area of Life</Table.Th>
            <Table.Th>
              Activated {skills?.filter((s) => s.activated).length}/
              {skills?.length}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {skills &&
            skills.map((skill: Skill) => (
              <Table.Tr
                key={skill.id}
                onClick={() => handleOpenModal(skill)}
                style={{ cursor: "pointer" }}
              >
                <Table.Td>{skill.id}</Table.Td>
                <Table.Td>{skill.name}</Table.Td>
                <Table.Td>{skill.area ? skill.area.value : null}</Table.Td>
                <Table.Td>{skill.activated ? "🟢" : null}</Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setEditingSkill(null);
          form.reset();
        }}
        title={editingSkill ? "Edit Skill" : "Create New Skill"}
        size="lg"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group justify="space-between">
            <TextInput
              label="Name"
              placeholder="Enter skill name"
              {...form.getInputProps("name")}
              required
              w="50%"
            />
            <Switch
              {...form.getInputProps("activated", { type: "checkbox" })}
              size="md"
              onLabel="ON"
              offLabel="OFF"
              mt="lg"
              labelPosition="left"
            />
          </Group>
          <Group mt="md">
            <TextInput
              label="Background Image URL"
              placeholder="Enter a background image URL"
              {...form.getInputProps("backgroundImgUrl")}
            />
            <Image src={form.values.backgroundImgUrl} mt="md" w={200} />
          </Group>
          <RichTextEditorField
            content={form.values.content}
            onChange={(content) => form.setFieldValue("content", content)}
          />
          <JsonInput
            label="Auto evaluation table JSON"
            placeholder="Input JSON"
            {...form.getInputProps("ratingTableJSON")}
            mt="md"
          />
          <Textarea
            label="Description"
            placeholder="Enter skill description"
            mt="md"
            {...form.getInputProps("description")}
            required
          />
          <Select
            label="Area of Life"
            placeholder="Select an area of life"
            data={
              areasOfLife?.map((area) => ({
                value: area.id.toString(),
                label: area.emoji + " " + area.value,
              })) || []
            }
            mt="md"
            {...form.getInputProps("areaOfLifeId")}
            required
          />
          <Group mt="md"></Group>
          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={createEditSkillMutation.isPending}>
              {editingSkill ? "Update Skill" : "Create Skill"}
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default AdminSkills;
