import {
  Box,
  Text,
  Paper,
  Grid,
  Title,
  Badge,
  Group,
  Flex,
  Button,
} from "@mantine/core";
import { Skill } from "../dto/user";
import { areasOfLife } from "../dto/areasOfLife";
import { useNavigate } from "react-router-dom";

interface DomainViewProps {
  skillsByArea: Record<string, Skill[]>;
  getSkillData: (skill: Skill) => {
    level: number;
    importance: number;
    gap: number;
  };
}

const DomainView = ({ skillsByArea, getSkillData }: DomainViewProps) => {
  const navigate = useNavigate();

  return (
    <Box p="md">
      <Grid>
        {areasOfLife.map((area) => {
          const areaSkills = skillsByArea[area.value] || [];
          if (areaSkills.length === 0) return null;

          return (
            <Grid.Col key={area.id} span={{ base: 12, sm: 6, lg: 4 }}>
              <Paper shadow="sm" p="md" withBorder>
                <Flex align="center" mb="sm" gap="xs">
                  <Text size="xl">{area.emoji}</Text>
                  <Title order={3}>{area.title}</Title>
                  <Button
                    onClick={() => navigate(`/eval/area/${area?.id}`)}
                    variant="light"
                  >
                    Autoévaluation
                  </Button>
                </Flex>
                <Box>
                  {areaSkills.map((skill) => {
                    const { level, importance, gap } = getSkillData(skill);
                    return (
                      <Paper key={skill.id} p="xs" mb="xs" withBorder>
                        <Group justify="space-between">
                          <Text fw={500}>{skill.name}</Text>
                          <Group>
                            <Badge color="blue">Niveau: {level}</Badge>
                            <Badge color="grape">
                              Importance: {importance}
                            </Badge>
                            <Badge
                              color={
                                gap > 0 ? "red" : gap < 0 ? "green" : "gray"
                              }
                              variant="light"
                            >
                              Écart: {gap > 0 ? "+" : ""}
                              {gap}
                            </Badge>
                          </Group>
                        </Group>
                      </Paper>
                    );
                  })}
                </Box>
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DomainView;
