import { Box, Group, Select, Textarea } from "@mantine/core";
import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { areasOfLife } from "../dto/areasOfLife";
import { useViewportSize } from "@mantine/hooks";
import { useUpdateUser } from "../hooks/useUpdateUser";
import SaveStatus from "../Components/SaveStatus";
import { useSkills } from "../context/SkillsContext";
import AreaLevelsSection from "../Components/AreaLevelsSection";
import PrioritySkillsSection from "../Components/PrioritySkillsSection";

function debounce<T extends (...args: any[]) => any>(func: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout> | null;

  const debouncedFunction = function (this: any, ...args: Parameters<T>) {
    const context = this;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };

  debouncedFunction.cancel = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debouncedFunction;
}

const PersonalInfo = () => {
  const { user, setUser } = useContext(UserContext);
  const { skills } = useSkills();
  const { width } = useViewportSize();
  const [priorityArea, setPriorityArea] = useState("");
  const [dreams, setDreams] = useState("");
  const [goals, setGoals] = useState("");
  const [saveStatus, setSaveStatus] = useState<
    "saved" | "saving" | "unsaved" | "error" | null
  >(null);
  const [userModifiedSomething, setUserModifiedSomething] = useState(false);
  const isInitializing = useRef(true);
  const lastSavedValues = useRef({
    priorityArea: "",
    dreams: "",
    goals: "",
  });

  const updateUser = useUpdateUser({
    onSuccess: (data) => {
      setUser((prevUser) => ({
        ...prevUser,
        priorityArea: data.priorityArea,
        dreams: data.dreams,
        goals: data.goals,
      }));
      setSaveStatus("saved");
      lastSavedValues.current = {
        priorityArea: data.priorityArea,
        dreams: data.dreams,
        goals: data.goals,
      };
    },
    onError: () => {
      setSaveStatus("error");
    },
  });

  const debouncedSave = useRef(
    debounce(
      (updatedUser: {
        id: number;
        priorityArea: string;
        dreams: string;
        goals: string;
      }) => {
        if (saveStatus === "saving") {
          debouncedSave.cancel();
        }
        setSaveStatus("saving");
        updateUser.mutate(updatedUser);
      },
      2000
    )
  ).current;

  const handleSaveStatusChange = (
    status: "saved" | "saving" | "unsaved" | "error" | null
  ) => {
    setSaveStatus(status);
  };

  useEffect(() => {
    if (user) {
      if (isInitializing.current) {
        setPriorityArea(user.priorityArea || "");
        setDreams(user.dreams || "");
        setGoals(user.goals || "");
        lastSavedValues.current = {
          priorityArea: user.priorityArea || "",
          dreams: user.dreams || "",
          goals: user.goals || "",
        };
        isInitializing.current = false;
        setSaveStatus(null);
      } else if (
        user.id !== undefined &&
        (priorityArea !== lastSavedValues.current.priorityArea ||
          dreams !== lastSavedValues.current.dreams ||
          goals !== lastSavedValues.current.goals) &&
        (priorityArea !== "" || dreams !== "" || goals !== "")
      ) {
        setSaveStatus("unsaved");
        setUserModifiedSomething(true);
        debouncedSave({
          id: user.id,
          priorityArea,
          dreams,
          goals,
        });
      } else if (userModifiedSomething) {
        setSaveStatus("saved");
      }
    }

    return () => {
      debouncedSave.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityArea, dreams, goals, user]);
  return (
    <>
      <Group grow={width > 1200} align="flex-start" w="100%">
        <AreaLevelsSection skills={skills} user={user} setUser={setUser} />

        <Box>
          <Select
            label="Domaine de vie prioritaire"
            placeholder="Choisissez un domaine"
            data={areasOfLife.map((area) => ({
              value: area.value,
              label: area.title,
            }))}
            value={priorityArea}
            onChange={(value) => setPriorityArea(value || "")}
            style={{ width: 200 }}
            mt={16}
          />

          <PrioritySkillsSection onSaveStatusChange={handleSaveStatusChange} />
        </Box>
      </Group>

      <Group grow={width > 1200} align="flex-start" w="100%">
        <Textarea
          label="Vos rêves long terme"
          placeholder="Racontez-nous vos rêves à long terme"
          minRows={5}
          autosize
          value={dreams}
          onChange={(event) => setDreams(event.currentTarget.value)}
          mt={16}
          w="100%"
        />
        <Textarea
          label="Vos objectifs pour cette année"
          placeholder="Racontez-nous vos objectifs pour cette année"
          minRows={5}
          autosize
          value={goals}
          onChange={(event) => setGoals(event.currentTarget.value)}
          mt={16}
          w="100%"
        />
      </Group>
      <SaveStatus saveStatus={saveStatus} />
    </>
  );
};
export default PersonalInfo;
