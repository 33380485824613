import React, { useState } from "react";
import { Box, Button, Text, Stack, Avatar, Card, Title } from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { Journey } from "../dto/user";
import { getJourneyTitle } from "../utils/utils";
import ConfirmJourneyModal from "../Components/ConfirmJourneyModal";
import { confettiEffects } from "../utils/confettiEffects";
import { useNavigate } from "react-router-dom";

const SelectJourney = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [selectedJourney, setSelectedJourney] = useState<Journey | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data: journeys, isLoading } = useQuery({
    queryKey: ["available-journeys"],
    queryFn: async () => {
      const response = await api.get("/journey/available-journeys");
      return response.data as Journey[];
    },
  });

  const startJourneyMutation = useMutation({
    mutationFn: (journeyId: number) =>
      api.post("/journey/start", { journeyId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["available-journeys"] });
      queryClient.invalidateQueries({ queryKey: ["userJourneys"] });
      queryClient.invalidateQueries({ queryKey: ["mainJourney"] });
      setIsModalOpen(false);
      setSelectedJourney(null);

      // Déclencher un effet de confetti aléatoire
      const randomEffect =
        confettiEffects[Math.floor(Math.random() * confettiEffects.length)];
      randomEffect();
    },
  });
  const handleJourneyClick = (journey: Journey) => {
    setSelectedJourney(journey);
    setIsModalOpen(true);
  };

  const handleConfirmStart = () => {
    if (selectedJourney?.id) {
      startJourneyMutation.mutate(selectedJourney.id);
    }
  };

  if (isLoading) {
    return <Box>Chargement...</Box>;
  }

  return (
    <Box p={16}>
      <Title mb="md">Parcours disponibles</Title>
      {(journeys || []).filter((journey) => (journey.users || []).length > 0)
        .length > 0 && (
        <Button
          color="green"
          onClick={() => {
            navigate("/");
          }}
          size="xl"
          mb="md"
        >
          Commencer{" "}
          {(journeys || []).filter(
            (journey) => (journey.users || []).length > 0
          ).length > 1
            ? "les"
            : "le"}{" "}
          parcours
        </Button>
      )}

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gap: "1rem",
        }}
      >
        {journeys?.map((journey) => (
          <Card
            key={journey.id}
            padding="lg"
            radius="md"
            style={{
              backgroundColor: journey.backgroundColor,
              flex: "0 0 auto",
            }}
          >
            <Stack align="center" gap="sm">
              <Avatar src="/logo_purple.png" alt="Journey icon" size={80} />
              <Text fw={500} size="lg" ta="center" mb="xs" c="black">
                {getJourneyTitle(journey)}
              </Text>
              {journey.users && journey.users.length > 0 ? undefined : (
                <Button
                  variant="filled"
                  color="violet"
                  fullWidth
                  onClick={() => handleJourneyClick(journey)}
                >
                  Démarrer
                </Button>
              )}
            </Stack>
          </Card>
        ))}
      </Box>

      {selectedJourney && (
        <ConfirmJourneyModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedJourney(null);
          }}
          onConfirm={handleConfirmStart}
          journey={selectedJourney}
          isLoading={startJourneyMutation.isPending}
        />
      )}
    </Box>
  );
};

export default SelectJourney;
