// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, ReactNode, useEffect } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import Home from "./Pages/Home";
import Landing from "./Pages/Landing";
import AreasOfLife from "./Pages/AreasOfLife";
import { Users } from "./Pages/Users";
import Settings from "./Pages/Settings";
import AdminSkills from "./Pages/AdminSkills";
import Skill from "./Pages/Skill";
import AdminQuests from "./Pages/AdminQuests";
import User from "./Pages/User";
import { ResetPassword } from "./Pages/ResetPassword";
import FeedbackPage from "./Pages/FeedbackPage";
import AdminFeedbackPage from "./Pages/AdminFeedbackPage";
import MiniGame from "./Pages/MiniGame";
import AdminMiniGames from "./Pages/AdminMiniGames";
import Achievements from "./Pages/Achievements";
import Admin from "./Pages/Admin";
import GamesHistory from "./Pages/GamesHistory";
import Quests from "./Pages/Quests";
import Statistics from "./Pages/Statistics";
import InterlocutorManagement from "./Pages/InterlocutorManagement";
import MiniGames from "./Pages/MiniGames";
import Domains from "./Pages/Domains";
import Eval from "./Pages/Eval";
import OnboardingForm from "./Components/onboarding/OnboardingForm";
import QuizGenerator from "./Pages/QuizGenerator";
import AdminJourneys from "./Pages/AdminJourneys";
import AdminJourneyDetail from "./Pages/AdminJourneyDetail";
import SelectJourney from "./Pages/SelectJourney";
import Brain from "./Pages/Brain";
import Privacy from "./Pages/Privacy";

interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const { user } = useContext(UserContext);

  if (!user || typeof user.role === "undefined") {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default function Router() {
  const { isLoggedIn } = useContext(UserContext);
  // const location = useLocation();

  // useEffect(() => {
  //   const scrollToTop = () => {
  //     document.body.scrollIntoView({ behavior: "auto", block: "start" });
  //   };
  //   scrollToTop();
  // }, [location]);

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path="/areas/:area" element={<AreasOfLife />} />
          <Route path="/skills/:skillId" element={<Skill />} />
          <Route path="/users" element={<Users />} />
          <Route path="/quests" element={<Quests />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/domains" element={<Domains />} />
          <Route path="/brain" element={<Brain />} />
          <Route path="/mini-game/:id" element={<MiniGame />} />
          <Route
            path="/admin-skills"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminSkills />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-quests"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminQuests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-feedbacks"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminFeedbackPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-mini-games"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminMiniGames />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/games-history"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <GamesHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/interlocutors"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <InterlocutorManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/quiz-generator"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <QuizGenerator />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-journey"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminJourneys />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-journey/:journeyId"
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <AdminJourneyDetail />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Home />} />
          <Route path="select-journey" element={<SelectJourney />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/onboarding" element={<OnboardingForm />} />
          <Route path="/mini-games" element={<MiniGames />} />
          <Route path="/eval/area/:areaId" element={<Eval />} />
          <Route path="/eval/skill/:skillId" element={<Eval />} />
        </>
      ) : (
        <>
          <Route path="*" element={<Landing />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </>
      )}
      <Route path="/user/:pseudo" element={<User />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
}
