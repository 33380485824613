import React from "react";
import {
  Box,
  Title,
  Text,
  Container,
  Stack,
  Divider,
  Paper,
  Group,
  Anchor,
} from "@mantine/core";

const Privacy: React.FC = () => {
  return (
    <Box p={16}>
      <Container size="md">
        <Group justify="space-between" mb={16}>
          <Title>Privacy Policy</Title>
        </Group>
        <Text c="dimmed" mb={24}>
          Last Updated: March 9, 2025
        </Text>

        <Paper p="md" withBorder>
          <Stack gap="xl">
            <Box>
              <Title order={3}>Introduction</Title>
              <Text mt={8}>
                Welcome to GrowSpace. We respect your privacy and are committed
                to protecting your personal data. This Privacy Policy explains
                how we collect, use, and safeguard your information when you use
                our mobile application.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Information We Collect</Title>

              <Title order={4} mt={16}>
                Personal Data
              </Title>
              <Text mt={8}>When you use GrowSpace, we may collect:</Text>
              <Box component="ul" ml={20} mt={8}>
                <Box component="li">
                  <Text>
                    <strong>Account Information</strong>: Email address,
                    username, and profile picture (if provided)
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>User-Generated Content</strong>: Skills assessments,
                    quests, goals, dreams, and feedback
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>Profile Information</strong>: Gender, age range,
                    relationship status, and whether you have children (if
                    provided)
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>Usage Data</strong>: Information on how you interact
                    with the app
                  </Text>
                </Box>
              </Box>

              <Title order={4} mt={16}>
                Analytics Data
              </Title>
              <Text mt={8}>
                We use analytics services to improve our application. These
                services may collect:
              </Text>
              <Box component="ul" ml={20} mt={8}>
                <Box component="li">
                  <Text>
                    <strong>Device Information</strong>: Device type, operating
                    system version
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>Usage Statistics</strong>: Features used, time spent
                    in the app, frequency of use
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>Performance Data</strong>: App crashes, loading
                    times, and other technical metrics
                  </Text>
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>How We Use Your Information</Title>
              <Text mt={8}>We use your information to:</Text>
              <Box component="ul" ml={20} mt={8}>
                <Box component="li">
                  <Text>Provide and improve the GrowSpace application</Text>
                </Box>
                <Box component="li">
                  <Text>Create and maintain your account</Text>
                </Box>
                <Box component="li">
                  <Text>Deliver personalized content and recommendations</Text>
                </Box>
                <Box component="li">
                  <Text>Analyze usage patterns to enhance user experience</Text>
                </Box>
                <Box component="li">
                  <Text>Respond to your feedback and support requests</Text>
                </Box>
                <Box component="li">
                  <Text>Send important updates about the application</Text>
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Data Storage and Security</Title>
              <Text mt={8}>
                Your data is stored securely in our database. We implement
                appropriate technical and organizational measures to protect
                your personal information against unauthorized access,
                alteration, disclosure, or destruction.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Third-Party Services</Title>
              <Text mt={8}>
                GrowSpace uses the following third-party services:
              </Text>
              <Box component="ul" ml={20} mt={8}>
                <Box component="li">
                  <Text>
                    <strong>Google Analytics/Firebase</strong>: For app usage
                    analytics and performance monitoring
                  </Text>
                </Box>
                <Box component="li">
                  <Text>
                    <strong>Google OAuth</strong>: For authentication services
                  </Text>
                </Box>
              </Box>
              <Text mt={8}>
                These services have their own privacy policies, and we encourage
                you to review them.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Data Sharing</Title>
              <Text mt={8}>
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties. We may share anonymous,
                aggregated data for analytical purposes.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Your Rights</Title>
              <Text mt={8}>
                Depending on your location, you may have rights regarding your
                personal data, including:
              </Text>
              <Box component="ul" ml={20} mt={8}>
                <Box component="li">
                  <Text>Accessing the personal data we hold about you</Text>
                </Box>
                <Box component="li">
                  <Text>Correcting inaccurate data</Text>
                </Box>
                <Box component="li">
                  <Text>Deleting your data</Text>
                </Box>
                <Box component="li">
                  <Text>Restricting or objecting to our use of your data</Text>
                </Box>
                <Box component="li">
                  <Text>
                    Requesting a copy of your data in a structured, commonly
                    used format
                  </Text>
                </Box>
              </Box>
              <Text mt={8}>
                To exercise these rights, please contact us at
                contact@growspace.app.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Children's Privacy</Title>
              <Text mt={8}>
                GrowSpace is not intended for users under the age of 13 (or 16
                in the European Economic Area). We do not knowingly collect
                personal information from children.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Changes to This Privacy Policy</Title>
              <Text mt={8}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the "Last Updated" date.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Contact Us</Title>
              <Text mt={8}>
                If you have any questions about this Privacy Policy, please
                contact us at:
              </Text>
              <Box mt={8}>
                <Text>
                  Email:{" "}
                  <Anchor href="mailto:hellogrowspace@gmail.com">
                    hellogrowspace@gmail.com
                  </Anchor>
                </Text>
                <Text>
                  Website:{" "}
                  <Anchor href="https://uplyapp.com" target="_blank">
                    https://uplyapp.com
                  </Anchor>
                </Text>
              </Box>
            </Box>

            <Divider />

            <Box>
              <Title order={3}>Consent</Title>
              <Text mt={8}>
                By using GrowSpace, you consent to our Privacy Policy and agree
                to its terms.
              </Text>
            </Box>
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default Privacy;
